@import '../../common/style/styleguide';

.of-contact-form {
  @media screen and (max-width: 640px) {
    &__description {
      max-width: 95% !important;

      div {
        width: 100%;
      }
      > dl > div {
        margin: 0;
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 641px) {
    &__description {
      dl {
        column-gap: 25px;
        width: min(687px, calc(100vw - (9 * var(--eds-baseline))));
        > div {
          margin-bottom: 0;
        }
      }

      input {
        width: min(330px, calc(50vw - (6 * var(--eds-baseline))));
      }
    }
  }
}
