@import '../../common/style/styleguide';

.of-subscription-details__mobileid-content {
  .of-mobileid__description {
    margin: 0;
  }

  .of-mobileid__button {
    padding-left: 0;
  }

  .of-subscription-details__actions {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.of-mobileid {
  &__title {
    font-size: calc(calc(2 * var(--eds-font-size-baseline)));
    line-height: normal;
  }

  &__buttons {
    align-items: flex-start;
    padding-top: calc(2.5 * var(--eds-baseline));
  }

  &__thick-button {
    min-height: calc(5.5 * var(--eds-baseline));
    background-color: $ea-color-white;
    color: $ea-color-black;
    border-color: $ea-color-black;

    &:hover {
      background-color: $ea-color-white-smoke;
      border-color: $ea-color-black;
    }
  }
}

.ea-icon--mobiilivarmenne-hero {
  height: 90px;
  width: 90px;
  background: transparent url('../../common/svg/mobiilivarmenne-logo.svg') left top no-repeat;
}

.of-icon--mobiilivarmenne {
  &:before {
    content: '';
    background-size: contain;
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-right: 1.5rem;
    vertical-align: middle;
    background: transparent url('../../common/svg/mobiilivarmenne-logo.svg') center center no-repeat;
  }
}
