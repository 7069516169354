@import '../../common/style/styleguide';

$icon-size: 110px;
// Hover color not in DS variables
$button-hover-color: #005fd4;

.of-subscription-change-confirmation {
  margin: calc(var(--eds-baseline) * 6);

  &__header {
    margin-top: calc(var(--eds-baseline) * 3);
    display: flex;
    flex-direction: row;

    &__icon {
      display: flex;
      align-items: center;
      padding-bottom: calc(var(--eds-baseline) * 3);
      @media only screen and (min-width: $breakpoint-s) {
        padding-bottom: 0;
      }

      &__mbb {
        background: transparent url('../../common/svg/picto-mobilebb.svg') center / cover no-repeat;
        width: $icon-size;
        height: $icon-size;
      }

      &__sim {
        background: transparent url('../../common/svg/picto-sim.svg') center / cover no-repeat;
        width: $icon-size;
        height: $icon-size;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: calc(var(--eds-baseline) * 3);

      &__wrapper {
        font-family: var(--eds-header-font-family-bold);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding-top: var(--eds-baseline);

        @media only screen and (min-width: $breakpoint-m) {
          flex-direction: row;
        }

        &__product-name {
          font-size: calc(var(--eds-baseline) * 3);
        }

        &__product-price {
          font-size: calc(var(--eds-baseline) * 2.5);
          padding-top: var(--eds-baseline);
          @media only screen and (min-width: $breakpoint-m) {
            padding: 0;
          }
        }
      }
    }
  }

  &__details__wrapper {
    @media only screen and (min-width: $breakpoint-m) {
      margin-left: calc(var(--eds-baseline) * 15);
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    border: solid 1px var(--eds-color-grey-300);
    margin-top: calc(var(--eds-baseline) * 3);

    &__content {
      padding: calc(var(--eds-baseline) * 3);

      &__header {
        font-size: calc(var(--eds-baseline) * 2.5);
        font-family: var(--eds-header-font-family-bold);
        padding-bottom: var(--eds-baseline);
      }

      &__value {
        padding-bottom: calc(var(--eds-baseline) * 3);
      }
    }

    &__order-summary {
      margin-top: calc(var(--eds-baseline) * 3);
    }

    &__buttons {
      margin-top: calc(var(--eds-baseline) * 3);
      display: flex;
      justify-content: flex-end;

      .ds-button:nth-child(2) {
        background-color: $ds-color__blue-600;
        border-color: $ds-color__blue-600;
        &:hover {
          background-color: $button-hover-color;
          border-color: $button-hover-color;
        }
      }
    }
  }
}
