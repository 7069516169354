@import '../../common/style/styleguide';

.of-subscription-action-details {
  &__content {
    &--contact-info {
      border-top: $global-border-normal solid $ea-color-smoke;
      border-bottom: $global-border-normal solid $ea-color-smoke;
    }

    &--request-info {
      padding-bottom: calc(3 * var(--eds-baseline));

      > h3 {
        padding-bottom: 1.5 * $global-baseline;
        padding-top: 1.5 * $global-baseline;
      }
    }
  }

  &__of-footer-warning {
    text-align: center;

    &--exclamation-mark {
      background: url('../../common/svg/icon-exclamation-mark.svg') no-repeat;
      height: 28px;
      position: absolute;
      width: 28px;
    }

    .ea-disclaimertext {
      margin-left: $global-baseline * 3;

      span {
        text-decoration: underline;
      }
    }
  }

  &__no-row-interaction:hover {
    transform: none;
    transition: none;
    box-shadow: none;
    cursor: auto;
  }
  & .of-details-wrapper {
    &__hero-right {
      max-width: 100%;
    }
  }
}
