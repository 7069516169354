@import '../../common/style/styleguide';

.of-order-summary {
  background-color: var(--eds-color-grey-100);
  border: 1px solid var(--eds-color-grey-300);
  padding: calc(3 * var(--eds-baseline)) calc(2 * var(--eds-baseline));
  width: 100%;
  box-sizing: border-box;

  &__details-toggle {
    background: transparent;
    border: none;
    color: $ds-color__blue-600;
    // make button text level with the table text
    margin-right: calc(2 * var(--eds-baseline));
    padding: 0;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      color: $ds-color__blue-800;
    }
  }
}
