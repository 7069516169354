@import '../../common/style/styleguide';

.mbb-alternatives {
  margin: calc(var(--eds-baseline) * 6) calc(var(--eds-baseline) * 2) 0 var(--eds-baseline);

  .mbb-alternatives-info {
    .infobox-container {
      grid-template-columns: 1fr; // One column on small screens
      grid-column-gap: var(--eds-baseline);
      @media only screen and (min-width: $breakpoint-s) {
        display: grid;
        grid-template-columns: 1fr 1fr; // Two columns of equal width
        gap: var(--eds-baseline);
      }

      &-item {
        background-color: var(--eds-color-grey-200);
        padding: calc(var(--eds-baseline) * 4);
        margin-bottom: var(--eds-baseline);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media only screen and (min-width: $breakpoint-s) {
          margin-bottom: 0;
        }

        .icon-container-liikkuva-wifi {
          display: grid;
          grid-template-columns: repeat(3, min-content);
          grid-template-rows: repeat(2, 1fr);
        }

        .icon-container-yritysdata {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
          grid-template-rows: repeat(2, 1fr);
          justify-content: start;
        }

        .icon-container-item {
          text-align: center;
          white-space: nowrap;
        }

        .link-button-container {
          align-self: flex-start; // Align the button container to the start of the cross axis
          margin-top: auto; // Push the button container to the bottom
          // CL does not support "transparent" anymore, so set colors here
          .ds-button {
            background-color: $ds-color__neutral-200;
            color: $ds-color__blue-800;
            border-color: $ds-color__blue-800;
          }
        }

        > h3 {
          margin-block-end: calc(var(--eds-baseline) * 1);
        }
      }
    }
  }

  .mbb-feature-comparison {
    margin: calc(var(--eds-baseline) * 2) 0 calc(var(--eds-baseline) * 8) 0;
  }
}
