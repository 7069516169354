@import '../../common/style/styleguide';

.of-epp-maintenance-service-request-form {
  hr {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  &--radio-group-header {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: calc(2 * var(--eds-baseline));

    span {
      padding-bottom: 10px;
    }
    &__title {
      font-size: larger;
      font-weight: 600;
    }
  }

  &-device_details {
    &-value {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      // TODO !important is only only needed for testing purposes (storybooks and screenshot creation), without it padding
      // is ignored. This might get fixed in the future with storybook updates so it's safe to remove !important if
      // screenshots stays the same without it.
      padding-bottom: 30px !important;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__message {
    p {
      font-weight: 600;
    }
    .ea-input {
      padding-top: 0;
    }
  }
}
