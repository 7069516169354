@import '../../common/style/styleguide';

.of-device-checkout-delivery-details {
  padding-top: calc(1.5 * var(--eds-baseline));

  &__list {
    gap: var(--eds-baseline);
    padding-bottom: calc(6 * var(--eds-baseline));
    padding-top: calc(1.5 * var(--eds-baseline));

    > div:first-child {
      margin-bottom: calc(2 * var(--eds-baseline));
      margin-top: calc(1.5 * var(--eds-baseline));
    }

    .ds-button {
      border: 1px solid var(--eds-color-grey-400);
      flex-basis: 100%;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: calc(var(--eds-baseline) * 3);
      padding: calc(var(--eds-baseline) * 2);
      position: relative;
      text-transform: unset;
    }

    &--container {
      text-align: left;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }

    &--price {
      font-weight: normal;
      @include breakpoint(tablet-and-up) {
        position: absolute;
        right: calc(2 * var(--eds-baseline));
        top: 30%;
      }
    }

    &--notes {
      font-weight: 100;
      font-size: 14px;
      flex-basis: 100%;
    }

    &--shipping-type {
      @include breakpoint(tablet-and-up) {
        flex: 0 1 80%;
      }
    }

    &--icon {
      min-height: 32px;
      height: 100%;
      min-width: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 2rem;
      align-self: center;
    }

    .matkahuolto {
      background-image: url('../../common/svg/matkahuolto-logo.svg');
    }

    .posti {
      background-image: url('../../common/svg/posti-logo.svg');
    }

    &__method-list {
      display: flex;
      flex-wrap: wrap;
      gap: var(--eds-baseline);

      .selected {
        border: 2px solid $ds-color__blue-600;
        padding: calc(var(--eds-baseline) * 2 - 1px);
      }
    }
  }

  &__store-options {
    span {
      font-size: 16px;
      vertical-align: text-bottom;
    }
  }

  &__disclaimer {
    font-size: 13px;
    &--grey {
      color: $ea-color-grey;
    }
  }

  .of-multiselector {
    border: 0;
    padding: 0;
  }
}

.of-device-checkout-delivery-mode {
  padding-bottom: calc(1.5 * var(--eds-baseline));
  padding-top: calc(3 * var(--eds-baseline));

  &__employee-info {
    padding-bottom: calc(3 * var(--eds-baseline));
  }

  &__non-employee-info {
    padding-bottom: calc(2 * var(--eds-baseline));
  }
}

.of-device-checkout-delivery-mode,
.of-device-checkout-order-shipment-type {
  &__list {
    display: flex;
    gap: var(--eds-baseline);
    justify-content: space-evenly;

    @include breakpoint(phone-large-and-down) {
      flex-direction: column;
    }

    .ds-button {
      align-items: center;
      border-color: var(--eds-color-grey-400);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      box-sizing: border-box;
      display: flex;
      flex-basis: 80px;
      flex-grow: 1;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0;
      padding: 1.6rem 3rem;
      text-transform: unset;
    }

    > button.selected {
      border: 2px solid $ds-color__blue-600;
      padding: calc(1.6rem - 1px) calc(3rem - 1px);
    }

    & &--icon,
    & &--icon.ds-icon {
      flex-shrink: 0;
      height: 35px;
      // ds-button has a rather high specificity selector for this...
      margin-right: 3rem !important;
      width: 35px;
    }

    &--description {
      text-align: left;
    }
  }

  .divider {
    align-items: center;
    display: flex;
    height: calc(3 * var(--eds-baseline));

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      border: 0 solid $ea-color-smoke;
      border-bottom-width: 1px;
    }
  }
  .vertical-align-bottom {
    vertical-align: bottom;
  }

  .icon {
    &-total-delivery {
      background: url('../../common/svg/icon-total-delivery.svg') no-repeat;
      height: 28px;
      width: 28px;
    }

    &-partial-delivery {
      background: url('../../common/svg/icon-partial-delivery.svg') no-repeat;
    }
  }
}

.of-device-checkout-order-shipment-type {
  margin-bottom: calc(3 * var(--eds-baseline));

  > div:first-child {
    margin-bottom: calc(2 * var(--eds-baseline));
  }

  > div:nth-child(2) {
    margin-bottom: 1.5 + var(--eds-baseline);
  }

  > div:nth-child(3) {
    margin-bottom: var(--eds-baseline);
  }
}

.of-device-checkout-delivery-address {
  margin-bottom: calc(3 * var(--eds-baseline));

  > div:first-child {
    margin-bottom: calc(2 * var(--eds-baseline));
  }
}

.of-validation-error-messages {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 4px;
}
