@import '../../common/style/styleguide';

.of-mobile-subscription-accordions {
  &__attach-subscription {
    .ds-contentWrapper {
      > div {
        padding-bottom: calc(3 * var(--eds-baseline));

        > div {
          padding-bottom: $global-baseline;
          padding-top: $global-baseline;
        }
      }
    }
  }
}
