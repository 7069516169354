@import '../../common/style/styleguide';

.of-shopping-basket-wrapper {
  .of-heading-and-company-selector {
    margin: 0 auto;
    max-width: 1320px;

    .of-shopping-basket-header {
      @include breakpoint(tablet-and-up) {
        padding-top: 36px;
      }
      @include breakpoint(tablet-and-down) {
        padding-top: calc(var(--eds-baseline) * 4);
      }
    }
    @include breakpoint(laptop-and-down) {
      padding-left: calc(var(--eds-baseline) * 3);

      .of-shopping-basket-popover-padding {
        padding-top: calc(var(--eds-baseline) * 14);
      }
    }
  }

  .of-shopping-basket-content {
    margin: 0 auto;
    max-width: 1320px;

    .ds-shoppingcart-content {
      h1 {
        // Don't use DS Shopping Cart heading (more info: ShoppingBasket.tsx --> i18nHeading)
        display: none;
      }
    }

    .ds-shoppingcart__product-name-and-description {
      word-break: break-word;
    }

    .ds-shoppingcart__product-disclaimer {
      div:nth-child(n + 4) {
        background-color: $ds-color__orange-300;
        width: max-content;
        padding: $global-baseline * 0.5 $global-baseline;
        float: right;
        margin-top: $global-baseline * 0.5;
        margin-bottom: $global-baseline * 0.5;
        margin-left: $global-baseline * 0.5;

        // Copied from DS-badge..
        border-radius: 100px;
        font-weight: 600;
        line-height: 1.25rem;

        @include breakpoint(phone-large-and-down) {
          float: left;
          margin-left: unset;
          margin-right: $global-baseline * 0.5;
        }
      }

      div:nth-child(n + 5) {
        background-color: $ds-color__turquoise-400;
      }

      div:nth-child(n + 6) {
        background-color: $ds-color__secondary-blue-300;
      }
    }
  }

  .of-shopping-basket-select-company-wrapper {
    .ds-popover-trigger {
      @include breakpoint(tablet-and-down) {
        min-width: 260px;
      }
      .ds-input--labelarea {
        text-align: left;
      }
      // Popover wraps component under button and breaks font styles
      li {
        font-family: $global-font-normal;
      }
    }

    .of-shopping-basket-select-company {
      padding-bottom: 3 * $ds_baseline;
    }

    .ds-input--labelarea {
      margin-bottom: 8px;
    }

    label {
      @include breakpoint(laptop-and-down) {
        font-size: 14px;
        line-height: 36px;
      }
      @include breakpoint(phone-large-and-down) {
        font-size: 13px;
        line-height: 36px;
      }
      @include breakpoint(laptop-and-up) {
        font-size: 16px;
        line-height: 42px;
      }
      font-weight: 400;
    }
  }

  .of-shopping-basket-popover-trigger,
  .of-shopping-basket-select-company-wrapper {
    display: inline-block;
  }

  @media only screen and (min-width: 641px) {
    .ds-shoppingcart-wrapper .ds-shoppingcart__product-price-options {
      grid-column: 1 / 3;
    }
  }

  @media only screen and (max-width: $breakpoint-l-max-width) {
    .of-shopping-basket-select-company-wrapper {
      padding-right: $ds_baseline * 3;
    }
  }
  @media only screen and (min-width: $breakpoint-l-max-width) {
    .of-shopping-basket-select-company-wrapper {
      padding-left: calc(var(--eds-baseline) * 4);
    }
  }

  @media only screen and (max-width: 1201px) {
    .of-with-horizontal-padding {
      padding-left: calc(var(--eds-baseline) * 3);
      padding-right: calc(var(--eds-baseline) * 3);
    }
  }
  @media only screen and (min-width: 1200px) {
    .of-with-horizontal-padding {
      padding-left: calc(var(--eds-baseline) * 4);
      padding-right: calc(var(--eds-baseline) * 4);
    }
  }
  @media only screen and (min-width: $breakpoint-xl) {
    .of-shopping-basket-header {
      padding-left: calc(var(--eds-baseline) * 4);
    }
  }

  @media only screen and (min-width: $breakpoint-s) and (max-width: 1201px) {
    .of-shopping-basket-content {
      .ds-shoppingcart__product-info:has(.ds-dropdown-error) {
        padding-bottom: calc(var(--eds-baseline) * 4);
      }
    }
  }
}
