@import '../../common/style/styleguide';

@function ds_rem_scale($ds_rem) {
  @return $ds_rem * 1.6;
}

// font is already set with a body selector, we need a higher specificity.
html > body {
  // CL drops Arial
  font-family: 'Open Sans', Arial, sans-serif;
}

.override-enabled {
  a:hover {
    text-decoration: none;
  }

  .of-select-one-list__item {
    &:hover {
      color: #404041;
    }
  }

  // FIXME these should not be here, they are only needed because of pattern library CSS screwing up rem.
  // (i.e. all issues that are gone if we remove pattern-library should be in pattern-library-overrides)
  .ds-button--size-s,
  .ds-link--button-size-s {
    font-size: ds_rem_scale(0.6875rem);
    line-height: ds_rem_scale(1rem);
  }

  .ds-button--size-m,
  .ds-link--button-size-m {
    font-size: ds_rem_scale(0.8125rem);
    line-height: ds_rem_scale(1rem);
  }

  .ds-button--size-l,
  .ds-link--button-size-l {
    font-size: ds_rem_scale(0.875rem);
    line-height: ds_rem_scale(1.25rem);
  }

  .ds-badge {
    font-size: ds_rem_scale(0.8125rem);
    line-height: ds_rem_scale(1.25rem);

    &--text {
      padding: ds_rem_scale(0.125rem) ds_rem_scale(1rem);
    }

    &--numeric {
      border-radius: ds_rem_scale(0.75rem);
      height: ds_rem_scale(1.25rem);
      padding: 0 ds_rem_scale(0.375rem);
    }

    &--status::before {
      border-radius: ds_rem_scale(0.37rem);
      padding: ds_rem_scale(0.37rem);
    }
  }

  .ds-accordion__section__content {
    line-height: unset;
  }

  .ds-toggle__label {
    // TODO: leo 22.5.2023 - DELETE once we are rid of elisa-styleguide-min.css styles which sets `box-sizing: border-box` that causes the toggle component to be rendered slightly off.
    box-sizing: content-box;
  }
}

// We want cards' header images to always have the same size/aspect ratio.
// OfferCards doesn't allow adding classes to either the cards or their
//  images, so we have to do this for all of them.
// This can't really be fixed in CL either, as the CL component isn't
//  responsible for the images.
// A 'proper' fix would be to expose this with a prop in OfferCards
//  (headerImageAspectRatio or something like that).
// offerCard class is from cms-ui-templates' OfferCards component, which
//  limits these changes to only that component.
.offerCard {
  .ds-card-heading {
    // Note that this limits the changes to only the actual header image.
    // Cards with no header, or a header with an icon will be unaffected.
    picture {
      aspect-ratio: calc(16 / 9);
      display: block;

      img {
        // The default value (fill) would stretch the actual image data,
        //  so we force it to cover.
        object-fit: cover;
      }
    }
  }
}
