@import '../../common/style/styleguide';

.company-selector-for-list-views {
  margin-bottom: calc(var(--eds-baseline) * 2);

  .autocomplete-root {
    min-height: unset;
  }

  @media only screen and (min-width: $breakpoint-m) {
    margin-right: calc(var(--eds-baseline) * 2);
    margin-bottom: 0;
  }
}
