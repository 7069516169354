@import '../../common/style/styleguide';

.ai-chat {
  width: $breakpoint-m;

  .messages {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: calc(var(--eds-baseline) * 3) 0;
    height: auto;
    max-height: 50vh;
    flex: 1;
    overflow-y: auto;
  }

  .message {
    margin: calc(var(--eds-baseline) * 2) 0;
    border-radius: 10px;
    width: auto;
    max-width: 80%;

    p {
      margin: calc(var(--eds-baseline) * 2);
    }
  }

  .user-message {
    align-self: flex-end;
    border: 1px solid var(--eds-color-grey-300);
  }

  .assistant-message {
    align-self: flex-start;
    background-color: var(--eds-color-grey-100);

    p {
      min-height: 24px;
      min-width: 78px;
    }

    .cursor {
      display: inline-block;
      width: 10px;
      background-color: #000;
      animation: blink 1s step-end infinite;
    }
  }

  .loading-message {
    align-self: flex-start;
    background-color: var(--eds-color-grey-100);
  }

  @keyframes blink {
    from,
    to {
      background-color: transparent;
    }
    50% {
      background-color: black;
    }
  }
}
