@import '../../../../common/style/styleguide';

.of-dns-history-details {
  h5 {
    color: var(--eds-color-grey-600);
  }

  pre {
    border: 1px solid var(--eds-color-grey-300);
    background: var(--eds-color-grey-100);
    padding: 1em;
    border-radius: 0.2em;
    color: var(--eds-color-grey-600);
  }

  hr {
    height: 1px;
    background-color: var(--eds-color-grey-300);
    border: none;
  }
}
