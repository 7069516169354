@use '../../breakpoints.scss' as breakpoints;

.DynamicArticles {
  .resize-image img {
    max-width: 100%;
  }

  .row-gap {
    row-gap: 16px;
  }

  .card-height {
    height: 20em;
    @include breakpoints.breakpoint(tablet-and-down) {
      height: 100%;
    }
  }

  .card-width {
    width: 100%;
  }

  .no-padding {
    padding: 0px;
  }

  .otherArticle img {
    width: 100%;
    @include breakpoints.breakpoint(tablet-and-up) {
      height: 12em;
    }
  }

  .firstArticle img {
    width: 100%;
  }

  .post {
    text-decoration: none;
    cursor: pointer;
    width: 100%;
  }

  @media screen and (min-width: 480px) {
    .post-card--first {
      height: 100%;

      .post-card__title {
        height: unset;
        font-size: 24px;
      }

      .post-card__summary {
        display: block;
      }
    }
  }

  .post-card__category {
    margin-top: -8px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 2px;
    color: #0019af;
  }

  .post-card__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: normal;
    color: #404041;
    overflow: hidden;
    height: 4.2em;
  }

  .post-card__summary {
    display: none;
    color: #404041;
    font-size: 16px;
    line-height: 1.5;
    flex: 1 1 auto;
    margin-top: 9px;
  }

  .multiline-ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
  }
}
