@import '../../common/style/styleguide';

.of-employee-device-change {
  > h4 {
    padding-bottom: 1.5 * $global-baseline;
  }

  &__actions {
    padding-top: calc(2 * var(--eds-baseline));
  }

  &__map-devices {
    padding-bottom: $global-baseline;

    > h4 {
      padding-bottom: 1.5 * $global-baseline;
    }
  }

  &__no-device-change {
    padding-bottom: $global-baseline;
    padding-top: $global-baseline;
  }

  &__redeemable-devices {
    padding-top: 1.5 * $global-baseline;

    > h4:first-child {
      padding-bottom: calc(3 * var(--eds-baseline));
    }

    &-option-label {
      margin-top: 0;
      margin-bottom: 0;
    }
    &-option-sublabel {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 13px;
      color: $ea-color-grey;
    }
  }

  &__terms {
    li {
      padding: ($global-baseline * 0.5) ($global-baseline * 2);
      margin-right: 4px;
      border-radius: 2px;
      border-width: 1px;
      p {
        font-size: 1.5rem;
      }
    }
  }

  .redeem-or-return-selection {
    padding: 8px;
    text-align: center;
    font-weight: 600;
  }
}
