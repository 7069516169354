@import '../../common/style/styleguide';

.stepper-block {
  .ds-stepper ol {
    li.ds-stepper__step__sub::after {
      top: 2.725rem;
    }

    li:last-child svg {
      fill: var(--eds-color-white) !important;
    }

    li.ds-stepper__step .ds-stepper__step__name {
      color: var(--eds-color-blue-800);
    }
  }

  .ds-stepper__step__sub .ds-icon {
    background-color: var(--eds-color-white);
  }

  .status-color-neutral .ds-stepper ol li:last-child::before {
    background-color: var(--eds-color-grey-600);
  }

  .status-color-warning-light .ds-stepper ol li:last-child::before {
    background-color: var(--eds-color-yellow-300);
  }

  .status-color-green .ds-stepper ol li:last-child::before {
    background-color: var(--eds-color-green-600);
  }

  .status-color-blue .ds-stepper ol li:last-child::before {
    background-color: var(--eds-color-blue-800);
  }

  .status-color-salmon .ds-stepper ol li:last-child::before {
    background-color: var(--eds-color-red-500);
  }
}
