@import '../../common/style/styleguide';

.card-selection-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: calc(var(--eds-baseline) * 6) calc(var(--eds-baseline) * 2) calc(var(--eds-baseline) * 2) 0;
  @media only screen and (min-width: $breakpoint-s) {
    flex-direction: row;
    justify-content: left;
    align-items: start;
    margin-left: 0;
    margin-right: calc(var(--eds-baseline) * 8);
  }
  .icon {
    padding-bottom: calc(var(--eds-baseline) * 3);
    @media only screen and (min-width: $breakpoint-s) {
      padding-bottom: 0;
    }
    .picto {
      width: 70px;
      height: 70px;
      &.picto-mobilebb {
        background: transparent url('../../common/svg/picto-mobilebb.svg') center / cover no-repeat;
      }
      &.picto-sim {
        background: transparent url('../../common/svg/picto-sim.svg') center / cover no-repeat;
      }
      &.picto-broadband-m2m {
        background: transparent url('../../common/svg/picto-broadband-m2m.svg') center / cover no-repeat;
      }
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    text-align: center;
    @media only screen and (min-width: $breakpoint-s) {
      text-align: left;
      padding-left: calc(var(--eds-baseline) * 4);
    }
    p {
      font-size: 18px;
    }
  }
}
