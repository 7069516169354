@import '../../common/style/styleguide';

.of-user {
  height: 108px;
  background: transparent url('../../common/svg/picto-cl-user.svg') left top no-repeat;
  height: calc(9 * var(--eds-baseline));
  background-size: calc(9 * var(--eds-baseline));
}

.of-name-change-confirmation-dialog__heading {
  @include h3;
  margin-top: calc(2 * var(--eds-baseline));
}
