@import '../../common/style/styleguide';

.of-details-wrapper {
  position: relative;

  &__main-heading {
    margin: 0 0 4px 0;
  }

  &__hero {
    .of-details-wrapper__hero-grid-item {
      display: flex;
      align-items: center;
    }

    &--grey {
      border: 1px solid var(--eds-color-grey-100);
      background-color: var(--eds-color-grey-100);
    }

    &-grid-item {
      margin: calc(var(--eds-baseline) * 4) calc(var(--eds-baseline) * 2) calc(var(--eds-baseline) * 8);
      flex-direction: column;

      .of-details-wrapper__hero-picto {
        margin: 0 0 $global-baseline 0;
        height: 92px;
        width: 92px;
        min-width: 92px;
        min-height: 92px;
        background-position: center;
      }

      .of-details-wrapper__hero-icon {
        color: $ds-color__brand-blue;
        margin: 0 0 2 * $global-baseline;

        @include breakpoint(tablet-and-up) {
          margin: 0 4 * $global-baseline 0 0;
          &:before {
            font-size: $global-baseline * 5;
          }
        }
      }

      .of-details-wrapper__hero-image {
        width: 9 * $global-baseline;
        margin: 0 0 2 * $global-baseline;

        img {
          max-width: 9 * $global-baseline;
          max-height: 9 * $global-baseline;
          width: auto;
          height: auto;
          display: block;
          margin: 0 auto;
        }

        @include breakpoint(tablet-and-up) {
          margin: 0 3 * $global-baseline 0 0;
        }
      }

      .of-details-wrapper__hero-right {
        text-align: center;
      }

      @include breakpoint(tablet-and-up) {
        flex-direction: row;
        .of-details-wrapper__hero-right {
          text-align: start;
        }
        .of-details-wrapper__hero-picto {
          margin: 0 2 * $global-baseline 0 0;
        }
      }
    }
  }

  &__hero-right {
    display: inline-block;
    vertical-align: top;

    & > h2 {
      padding-bottom: 0;
    }

    &--full-width {
      max-width: unset;
    }
  }

  &__hero-right-top {
    margin-bottom: var(--eds-baseline);
    margin-top: 0;
  }

  &__content {
    margin: ($global-baseline * 4) calc(var(--eds-baseline) * 2) 0;
  }
}
