@import '../../common/style/styleguide';

.of-simple-summary {
  padding-bottom: calc(2 * var(--eds-baseline));

  tbody tr {
    td {
      text-align: right;
    }
  }

  @include breakpoint(phone) {
    display: flex;
    flex-direction: column;

    tbody tr {
      td {
        padding-bottom: 0;
        padding-top: var(--eds-baseline);
        text-align: left;
      }

      th {
        padding-bottom: 0;
        padding-top: calc(3 * var(--eds-baseline));
      }
    }

    tr {
      display: flex;
      flex-direction: column;
    }
  }
}
