@import '../../common/style/styleguide';

.of-employee-omalasku {
  &__content {
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 5%;
  }

  &__separator {
    height: 1px;
    margin-top: calc(var(--eds-baseline) * 7);
    background-color: var(--eds-color-grey-400);
    border: none;
  }

  &__ordering-separator {
    height: 1px;
    margin-top: var(--eds-baseline);
    background-color: var(--eds-color-grey-400);
    border: none;
  }

  &__table-end {
    margin: 0;
    border: none;
    background-color: var(--eds-color-grey-300);
    height: 1px;
  }

  &__ordering-header-text {
    display: flex;
    align-items: center;
    gap: calc(var(--eds-baseline) * 3);
  }

  &__ordering-payable-list {
    > li {
      margin-top: 0 !important;
    }
  }
}
