@import '../../common/style/styleguide';

// This has been made to follow the styling of the OneTrust banner as well as
// possible (and sensible).
.of-cookie-consent {
  background-color: $ds-color__blue-800;
  color: $ds-color__white;
  z-index: $zindex6;

  &__buttons {
    display: flex;
    flex: 1 40%;
    flex-direction: row;
    gap: calc(1.5 * var(--eds-baseline));
    justify-content: flex-end;

    > button {
      margin: calc(1.5 * var(--eds-baseline)) 0;
    }
  }

  &__content {
    border-bottom: 1px solid var(--eds-color-grey-200);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
  }

  &__text {
    flex: 2 60%;
  }

  a {
    color: $ds-color__blue-600;
    font-weight: bold;
    text-decoration: underline;
  }

  h2 {
    margin: 0;
    padding: 0 0 calc(1.25 * var(--eds-baseline)) 0;
  }

  .ds-button--color-light-inverse {
    border: none;
  }
}

@include breakpoint(phone-large-and-up) {
  .of-cookie-consent {
    a {
      white-space: nowrap;
    }
  }
}

@include breakpoint(laptop-and-up) {
  .of-cookie-consent {
    &__content {
      align-items: center;
      flex-direction: row;
      gap: calc(6 * var(--eds-baseline));
    }
  }
}

@include breakpoint(desktop) {
  .of-cookie-consent {
    &__buttons {
      align-items: center;
      flex-direction: row;
    }
  }
}
