@import '../../common/style/styleguide';

.of-omaelisa-main-container {
  margin-top: auto;
  color: $ds-color__primary-blue-800;

  .classic-link {
    display: block;
    margin-top: 4px;
    text-align: right;

    .ds-icon {
      height: 16px;
      width: 16px;
      vertical-align: text-top;
    }

    a {
      text-decoration: none;
    }
  }

  .category-filter {
    display: flex;
    border-radius: 20px;
    background-color: $ds-color__white;
    border: 1px solid var(--eds-color-grey-400);
    align-items: center;
    padding: 8px 16px 8px 16px;
    cursor: pointer;
  }

  .of-omaelisa-search-filter-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $ds-color-_neutral-300;
    padding-left: 16px;
    color: $ds-color__primary-blue-800;

    .of-omaelisa-search-filters {
      padding-top: 16px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex: 4 1;
      gap: 8px;

      .of-all-accounts-toggle {
        flex: 1 1 100%;
      }
    }

    .of-omaelisa--search-result-count {
      flex: 1 0 min-content;
      text-align: right;
    }

    .of-omaelisa-search--filter-count {
      padding-right: 5px;
      padding-left: 5px;
      margin-left: 8px;
      border-radius: 2px;
      background-color: $ds-color--neutral-300;
    }

    .selected {
      background-color: $ds-color__secondary-blue-600;
      color: $ds-color-_white;

      .of-omaelisa-search--filter-count {
        background-color: $ds-color-_secondary-blue-300;
        color: $ds-color__secondary-blue-800;
      }
    }
  }

  .of-omaelisa-search-result-type-container {
    padding-bottom: 8px;
    border-bottom: 1px solid $ds-color-_neutral-300;

    &__total {
      padding: 16px 16px 8px 16px;
    }
  }

  .of-omaelisa-search-results-container {
    background-color: white;
    overflow-y: auto;
    max-height: calc(100vh - (120px + 48px));
    box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.75);

    .skeleton-loader {
      width: 100%;
      padding: 16px;

      &__row {
        animation: skeleton-loading 1s linear infinite alternate;
        height: 70px;
        border-radius: 2px;
        margin-bottom: 8px;
      }
    }
  }

  .of-omaelisa-result-row {
    width: 100%;
    border: none;
    margin: 0;
    padding: 16px;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;

    display: flex;
    align-items: flex-start;
    gap: 0 16px;
    cursor: pointer;

    .result-icon {
      padding-top: 4px;
    }

    &__content {
      display: flex;
      flex-direction: row;
      flex: 1;

      .of-omaelisa-result-row-element-data {
        display: flex;
        gap: 4px 0;
        flex-direction: column;
        text-align: left;

        span {
          padding-right: 8px;

          &.ds-badge {
            padding-right: 0;
          }
        }

        span.header {
          font-weight: bold;
        }

        .data-row {
          .info-text {
            display: inline;

            div {
              display: inline;
              padding-right: 4px;
            }
          }

          .status-wrapper {
            display: inline;

            div {
              display: inline-block;
            }
          }

          .epp-badge {
            margin-right: 10px;
            padding: 0 3px 0 3px;
          }

          .ds-icon {
            padding: 0 6px 0 0;
          }
        }
      }

      .result-company-name {
        margin-left: auto;
      }
    }
  }

  .of-omaelisa-result-row:hover {
    background: var(--eds-color-grey-200);
  }

  .search-info-text,
  .no-results {
    text-align: center;
  }

  .search-info-text {
    padding: 16px;
  }

  .no-results {
    padding: 24px 16px 16px 16px;
  }

  .too-much-results {
    .search-info {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .search-info {
    padding: 16px;
    border-radius: 12px;

    .search-info-text {
      background-color: var(--eds-color-grey-100);
      padding: 16px;

      p {
        margin: 0;
      }

      h5 {
        margin-top: 0;
        margin-bottom: 0;
      }

      h5 + p {
        margin-top: 4px;
        margin-bottom: 0;
      }
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--eds-color-grey-300);
  }
  100% {
    background-color: var(--eds-color-grey-200);
  }
}

@media only screen and (max-width: $breakpoint-m-max-width) {
  .of-omaelisa-main-container {
    .of-omaelisa-search-results-container {
      box-shadow: none;
    }
  }
}

@media only screen and (max-width: $breakpoint-s-max-width) {
  .of-omaelisa-main-container {
    width: 100%;

    .of-omaelisa-search-results-container {
      max-height: calc(100vh - (120px));

      .of-omaelisa--search-result-count {
        text-align: left;
        padding-top: 8px;
        padding-bottom: 8px;

        &__multibiz-with-no-filters {
          padding-top: 16px;
        }
      }

      .of-all-accounts-toggle {
        flex-direction: column;
      }
    }

    .of-omaelisa-result-row {
      .result-icon {
        padding: 0;
      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: 4px 0;

        .of-omaelisa-result-row-element-data {
          .data-row {
            div {
              display: flex;
              flex-direction: column;
              gap: 4px 0;
            }

            .bar {
              display: none;
            }

            .info-text,
            .status-wrapper {
              display: block;
            }
          }
        }

        .result-company-name {
          text-align: left;
          margin: 0;
        }
      }
    }

    .classic-link {
      margin-top: 5px;
      text-align: left;
    }
  }
}
