@import '../../common/style/styleguide';

.of-button-group-submit-and-back {
  &__reverse {
    display: flex;
    flex-direction: row-reverse;
  }
  button.margin-left {
    margin-left: var(--eds-baseline);
  }
}
