@import '../../common/style/styleguide';

.of-mobile-id-addon {
  &__button {
    flex-direction: column;
    display: flex;
    align-items: center;
    &__text {
      font-size: calc(calc(var(--eds-font-size-baseline) * 0.7));
      color: var(--eds-color-black);
    }
  }
}
