@import '../../common/style/styleguide';

.of-fixed-broadband-availability {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    flex: 1;
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    height: 100px;
    width: 100px;

    &--home-office {
      background: transparent url('../../common/svg/home_office.svg') left top no-repeat;
    }

    &--m2m {
      background: transparent url('../../common/svg/m2m.svg') left top no-repeat;
    }

    &--mobilebroadband {
      background: transparent url('../../common/svg/mobilebroadband.svg') left top no-repeat;
    }

    &--email {
      background: transparent url('../../common/svg/eMail.svg') left top no-repeat;
    }

    &--shopping-bag {
      background: transparent url('../../common/svg/shoppingBag.svg') left top no-repeat;
    }

    &--yritysguru {
      background: transparent url('../../common/svg/yritysguru.svg') left top no-repeat;
    }
  }

  &__address-search {
    &__image-container {
      display: flex;
      justify-content: center;

      @include breakpoint(laptop-and-up) {
        justify-content: flex-end;
      }
    }

    &--house {
      width: calc(100vw - 20px);
      height: min(calc((100vw - 20px) * (150 / 198)), 380px); // 150/198 are the picture resolution.
      max-width: 530px;
      background: transparent url('../../common/svg/house.svg') left top no-repeat;
    }
  }

  &__purposes {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      p {
        text-align: center;
      }

      @include breakpoint(laptop-and-up) {
        align-items: flex-start;

        p {
          text-align: unset;
        }
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include breakpoint(laptop-and-up) {
        align-items: flex-start;
      }
    }
  }

  &__technologies {
    ul {
      line-height: calc(var(--eds-baseline) * 4);
      list-style-type: initial;
    }
  }

  &__services {
    &__content {
      flex-direction: column;
      align-items: center;
      line-height: calc(var(--eds-baseline) * 6);
    }
  }
}
