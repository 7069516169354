@import '../../common/style/styleguide';

.of-esim-activation {
  &__qr {
    width: auto;

    img {
      width: 160px;
    }
  }

  &__qr-instructions {
    display: flex;

    ol {
      list-style-type: decimal;
    }

    ul {
      padding-left: calc(var(--eds-baseline) * 3);
      list-style-type: circle;
    }
  }

  &__break-word {
    word-wrap: break-word;
  }

  &__picto {
    margin: 0 0 0 0.4em;
    height: 22px;
    width: 22px;
    background-position: bottom;
  }

  &__copy {
    border-bottom: 1px dotted #000;
    text-align: left;
    text-decoration: none;
  }

  &__small-print {
    font-size: 1.3rem;
  }

  &__stepper * b {
    font-weight: 600;
  }

  &__button {
    text-align: left;
    text-transform: uppercase;
  }

  // Left margin is needed to keep the buttons from touching, but don't apply to first as it's not needed
  // and with narrow screen will cause the buttons to wrap unnecessarily.
  &__button:nth-child(n + 2) {
    margin-left: 2em;
  }

  &__actions {
    text-align: right;
    margin-top: 1em;
  }

  &__info {
    border-bottom: $global-border-normal solid $ea-color-smoke;
    padding: 1em 0 1em;
  }
}
