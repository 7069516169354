@import '../../common/style/styleguide';

.of-attach-pbx-content {
  &__pbx-selection {
    font-weight: 600;
    padding-bottom: 3 * $global-baseline;
  }

  &__service-level {
    padding-bottom: 3 * $global-baseline;
  }

  &__sub-numbers {
    display: flex;
    padding-top: $global-baseline;

    .combobox-container {
      display: inline-block;
      position: relative;
      z-index: 1;
    }
  }

  &__sub-number {
    max-width: 230px;

    @include breakpoint(laptop-and-up) {
      padding-right: 6%;
    }
  }

  &__subscription {
    padding-bottom: 3 * $global-baseline;
    padding-top: calc(3 * var(--eds-baseline));
  }

  &__number-range-row {
    display: inline-block;
    min-width: 115px;
    text-align: left;
  }

  &__error {
    .ds-disclaimer {
      color: $ea-color-grey;
    }
  }

  &__title {
    margin-bottom: 0;
  }

  .of-select-one-list__item--selected {
    .of-select-number-list {
      color: $ds-color__blue-600;
    }
  }

  .ea-bodytext + .ea-disclaimertext {
    padding-bottom: $global-baseline;
  }

  &__selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(3 * var(--eds-baseline)) 0;
    width: 100%;
  }
}
