@use '../../common/style/global-mixins';
@import '../../common/style/styleguide';

$card-heading-mobile-pad: calc(2 * var(--eds-baseline));

.of-public-mobile-broadband-subscriptions {
  @include global-mixins.page-margins-as-grid;

  > aside,
  > section {
    padding-bottom: calc(5 * var(--eds-baseline));
    padding-top: calc(5 * var(--eds-baseline));
    text-align: center;
  }

  > section > div {
    margin: 0 auto;
  }

  > header {
    margin-bottom: calc(8 * var(--eds-baseline));
    text-align: center;

    > h1 {
      margin-top: calc(4 * var(--eds-baseline));
    }
  }

  > nav:first-child,
  .of-page-margins--as-padding {
    @include global-mixins.page-margins-as-grid-bleed;
  }

  &__subscriptions {
    .of-subscription-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(min(100%, 324px), 1fr));
    }

    .of-subscription-card {
      max-width: unset;
    }
  }

  &__image-cards {
    max-width: 100%;
    text-align: center;

    &.ds-cardcontainer {
      .ds-card {
        &-content {
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: calc(2 * var(--eds-baseline));
          justify-content: flex-start;
          padding-left: 0;
          padding-right: 0;
          padding-top: 0;

          > img {
            &:not(.of-card-image--full-width) {
              height: 81px;
            }
          }

          > div {
            align-items: center;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: calc(2 * var(--eds-baseline));
            padding: var(--eds-baseline) 1rem 0 1rem;

            > a {
              margin-top: auto;
            }

            > h3 {
              margin: 0;
              padding: 0;
            }

            > p {
              margin-top: 0;
            }
          }
        }

        &-heading {
          display: none;
        }
      }
    }
  }

  .ds-accordion {
    .ds-list--unordered {
      margin-bottom: calc(2 * var(--eds-baseline));
      padding-left: calc(2 * var(--eds-baseline));
    }
  }
}

.of-public-mobile-broadband-subscriptions__marketing-block {
  > div {
    align-items: center;
    display: grid;
    gap: calc(6 * var(--eds-baseline));
    grid-template-columns: 1fr;
    max-width: 604px;
    text-align: left;

    img,
    picture {
      text-align: center;
      width: 100%;
    }

    img {
      object-fit: cover;
    }

    @include breakpoint(laptop-and-up) {
      grid-template-columns: 4fr 6fr;
      max-width: 1400px;

      $block-text-ratio: 0.6;
      > div {
        grid-column: 2;
        grid-row: 1;
      }

      > img,
      > picture {
        grid-column: 1;
        grid-row: 1;
        text-align: left;
      }
    }
  }
}
