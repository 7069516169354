@import '../../common/style/styleguide';

.of-promotion-block {
  // TODO remove when CL supports new PromotionText
  .legacy-promotion-text {
    color: #171c43;
    background-color: #ffaf38;
  }

  // TODO remove when CL.Badge supports new badges
  .legacy-employee-promotion-text {
    position: fixed;
    right: var(--eds-baseline);
    top: calc(var(--eds-baseline) * 0.5);
  }

  // TODO remove when CL.Badge supports new badges
  .legacy-badge-sale {
    background-color: #f66000;
  }

  // TODO remove when CL.Badge supports new badges
  .legacy-badge-special {
    background-color: #0019af;
  }

  // TODO remove when CL.Badge supports new badges
  .legacy-badge--out_of_stock {
    background-color: #f5f5f5;
    color: #303031;
  }

  .promotion-block {
    .ds-h2 {
      padding-top: $global-baseline * 2;
      padding-bottom: $global-baseline * 2;
    }
  }

  .promotion-block:hover {
    overflow-y: visible;
    overflow-x: clip;
  }

  .ea-color-container {
    &--gray-gradient {
      background: linear-gradient(45deg, rgba(247, 247, 247, 1) 0%, rgba(253, 253, 253, 1) 100%);
    }
    &--orange {
      background-color: $ea-color-orange;
      color: $ea-color-white;
    }
  }

  .ea-promotion-block {
    &__item {
      width: 100%;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      padding: $global-baseline $global-baseline;
      height: 100%;
    }

    &__image-container {
      // Make sure image doesn't affect layout (or anything else) outside itself.
      // This should make rendering faster, and guarantee that lazy loaded images
      // won't cause reflow.
      contain: layout paint style;
      display: block;
      position: relative;
      flex-grow: 1;
      height: 100%;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: 100%;
        max-height: 100%;
        padding: 3.5% 8%;
      }
    }

    &__prices {
      span {
        display: block;
        line-height: 24px;
      }
    }

    &__text {
      padding: $global-baseline $global-baseline 0;
    }

    &__title {
      font-weight: 600;
      line-height: 24px;
    }

    &__price {
      position: absolute;
      right: 0;
      top: 65%;
      min-width: 35%;
      max-height: 35%;

      .ea-bodytext {
        font-size: 1.3rem;
      }

      @include breakpoint(laptop-and-up) {
        .ea-bodytext {
          font-size: 1.6rem;
        }
      }
    }

    &__promotion-text {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      text-transform: uppercase;
      top: 15%;
      right: 10%;
      left: auto;
      width: 80px;
      height: 80px;
      font-size: 1.4rem;
      font-weight: 600;

      &--small {
        top: 5%;
        right: 5%;
        left: auto;
        width: 70px;
        height: 70px;
        font-size: 1.4rem;
        letter-spacing: 0.4px;
      }
    }

    &__square-image {
      // Make sure image doesn't affect layout (or anything else) outside itself.
      // This should make rendering faster, and guarantee that lazy loaded images
      // won't cause reflow.
      contain: layout paint style;
      flex: 0 0 auto;
      height: auto;
      position: relative;

      &::before {
        content: '';
        float: left;
        padding-top: 100%;
      }

      // TODO use aspect-ratio, object-fit and object-position?
      // That would allow us to remove the ::before hack I think.
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: 100%;
        max-height: 100%;
        padding: 3.5%;
      }
    }
  }
}
