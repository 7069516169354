@import '../../common/style/styleguide';

.of-mfa-otp-form {
  padding: calc(var(--eds-baseline) * 3);
  max-width: calc(var(--eds-baseline) * 59);

  .picto {
    margin: 0 0 var(--eds-baseline) 0;
    height: calc(var(--eds-baseline) * 8);
    width: calc(var(--eds-baseline) * 8);
    background: transparent url('../../common/svg/picto-lock.svg') left top no-repeat;
  }

  .otp-input {
    width: calc(var(--eds-baseline) * 20);
    letter-spacing: calc(var(--eds-baseline) / 2);
  }

  input[type='submit'] {
    width: 100%;
  }

  @media screen and (min-width: $breakpoint-s) {
    width: 100%;
    padding: calc(var(--eds-baseline) * 5);
  }
}
