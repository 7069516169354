@import '../../common/style/styleguide';

.of-sticky-footer {
  background-color: $ds-color__white;
  left: 0;
  bottom: 0;
  box-shadow: 0 -2px 2px 0 rgba(150, 150, 150, 0.3);
  position: fixed;
  width: 100%;
  z-index: 999999;
  transition: height 0.3s ease-in-out;

  &--placeholder--hidden,
  &--hidden {
    height: 0;
  }
  &--placeholder--visible,
  &--visible {
    height: calc(var(--eds-baseline) * 10);
    &.high-mobile {
      height: calc(var(--eds-baseline) * 18);
      @media only screen and (min-width: $breakpoint-m) {
        height: calc(var(--eds-baseline) * 10);
      }
    }
  }
}
