@import '../../common/style/styleguide';

$thumbnail-large: 126px;
$thumbnail-small: 50px;

.of-public-pages-search-promo {
  &-row {
    margin-bottom: calc(4 * var(--eds-baseline));
    min-height: $thumbnail-large;
    // These keep the item contents on screen when hovered
    margin-right: 5vw;
    @include breakpoint(laptop-and-up) {
      margin-right: 0;
    }

    &-item {
      background: $ds-color__white;
      border: 1.5px solid $ds-color__blue-600;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      gap: calc(3 * var(--eds-baseline));
      padding: calc(2 * var(--eds-baseline));
      transform-origin: center left;
      transition: all 0.2s ease-in-out;
      width: 100%;

      &:focus,
      &:hover {
        box-shadow: 0 0 12px -2px rgba(black, 0.3);
        transform: scale(1.05);
      }
    }

    &-thumbnail {
      align-items: center;
      display: flex;
      justify-content: center;
      width: $thumbnail-large;

      @include breakpoint(phone) {
        width: $thumbnail-small;
      }

      img {
        height: auto;
        max-height: $thumbnail-large;
        max-width: $thumbnail-large;

        @include breakpoint(phone) {
          max-height: $thumbnail-small;
          max-width: $thumbnail-small;
        }
      }
    }

    &-item {
      min-height: $thumbnail-large;
      width: 100%;

      &-container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-title {
        color: $ds-color__blue-600;
      }

      &-snippet {
        color: var(--eds-color-grey-700);
      }
    }
  }
}
