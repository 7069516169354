@import '../../common/style/styleguide';

.of-invoice-documents {
  .company-selector-for-list-views {
    margin-top: calc(var(--eds-baseline) * 3);
  }
  .of-table-wrapper .ds-table tr td {
    > a {
      align-items: center;
      display: flex;
      // This is here just to keep the rows as high as they were before.
      // They'd be a bit too dense without the padding.
      padding: 5px 0;
    }

    .ds-icon {
      display: inline-block;
      height: 22px;
      margin-right: 10px;
      width: 22px;

      svg {
        height: 22px;
        width: 22px;
      }
    }
  }
}
