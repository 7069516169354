@import '../../../common/style/styleguide';

.of-product-details-picture {
  img {
    max-width: 100%;
    max-height: 100%;
  }

  &__side-container {
    display: inline-block;
    position: relative;
    overflow: hidden;

    width: 100%;
    padding-bottom: 100%;
    box-sizing: border-box;

    border: $global-border-normal solid transparent;
    border-radius: $global-borderradius;

    &-picture {
      @include breakpoint(phone-large-and-down) {
        inset: 12px;
      }
      text-align: center;
      position: absolute;
      inset: 6px;
    }

    &--selected {
      border-color: var(--eds-color-grey-400);
    }
  }

  &__main-container {
    padding: 0 0 20px 20px;
    @media screen and (min-width: $breakpoint-l) {
      padding: 0;
    }
  }
}
