@import '../../common/style/styleguide';

.of-fixed-bb-yrityswifi-addon {
  border-left: 1px solid var(--eds-color-grey-300);
  border-right: 1px solid var(--eds-color-grey-300);
  &__price {
    margin: calc(1.25 * var(--eds-baseline)) 0;
  }

  &__additional-info {
    max-width: 550px;
  }

  &__radio {
    box-sizing: content-box;

    &-label,
    > label {
      width: 100%;
    }
  }

  &__installation_service {
    .ds-checkbox .ds-checkbox__label {
      width: 100%;
    }

    .of-fixed-bb-yrityswifi-addon__checkbox-label {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}
