@import '../../common/style/styleguide';

.of-device-checkout-thankyou {
  max-width: 904px;
  margin: 24px auto;

  &__notification {
    display: flex;
    padding-top: 45px;

    &-text {
      margin-left: 22px;
    }
  }

  &__product-list {
    &--bordered {
      border-bottom: 1px solid $ea-color-dark-smoke;
    }
  }

  &__delivery-details,
  &__item-details,
  &__company-details {
    padding-top: 2 * $global-baseline;
    position: relative;
    @extend %clearfix;

    &--heading {
      border-bottom: $global-border-normal solid $ea-color-smoke;
      padding-bottom: var(--eds-baseline);
    }
    &--price {
      @include breakpoint(tablet-and-up) {
        text-align: right;
      }
    }

    &--address {
      font-size: 13px;
      padding-top: 13px;
    }
  }

  &__company-details {
    > .ea-fgrid {
      padding-bottom: $global-baseline;
      padding-top: $global-baseline;

      > .ea-fgrid__item:nth-child(2) {
        padding-bottom: $global-baseline;
        padding-top: $global-baseline;

        @include breakpoint(tablet-and-up) {
          padding-bottom: 0;
          padding-top: 0;
        }
      }
    }
  }

  &__delivery-details {
    &--price {
      padding-bottom: $global-baseline;
      padding-top: $global-baseline;
    }

    @include breakpoint(tablet-and-up) {
      &--price {
        padding-bottom: 0;
        padding-top: 0;
      }

      .ds-icon {
        margin-left: 30px;
      }
    }
  }

  .of-order-summary {
    margin-top: 44px;
  }
}
