@import '../../common/style/styleguide';

.of-additional-service {
  border-bottom: 1px solid var(--eds-color-grey-300);

  &:last-child {
    border-bottom: none;
  }

  &__inactive {
    color: var(--eds-color-grey-600);
  }
  &__header {
    flex-wrap: wrap;
    flex-direction: row;
  }
  &__title,
  &__action {
    flex-direction: column;
    flex-grow: 1;
    margin-top: auto;
    margin-bottom: auto;
  }
  &__title,
  &__title:hover {
    flex-basis: 70%;
    color: unset;
  }
  &__action {
    flex-basis: 30%;
  }
  &__action {
    margin-bottom: 0;

    button {
      font-size: 1.6rem;
      text-transform: none;
      background-color: transparent;
      border: none;
    }
    button:hover {
      background-color: transparent;
      font-weight: bolder;
    }
  }
  &__price {
    align-items: flex-start;
    flex-direction: column;
    @media only screen and (min-width: $breakpoint-m) {
      align-items: flex-end;
    }
  }
}
