@import '../../common/style/styleguide';

.of-employee-subscription-details {
  &__usage {
    float: right;

    &__overused {
      color: $ea-color-error;
    }
  }

  &__voice {
    border-bottom: 1px solid $ea-color-smoke;
    padding-bottom: $global-baseline * 3;
  }

  &__actions {
    clear: left;
    display: block;
    padding-bottom: 3 * $global-baseline;
    padding-top: 3 * $global-baseline;

    a {
      display: inline-block;
    }

    button,
    a {
      margin-bottom: $global-baseline * 2;
      margin-right: 2.75em;
    }

    .ea-icon {
      margin-right: 1.25rem;
      vertical-align: middle;
    }

    @media screen and (min-width: $breakpoint-m) and (max-width: $breakpoint-m-max-width) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media screen and (min-width: $breakpoint-l) {
      display: inline-flex;
    }
  }

  .usage-info-grid {
    div[class^='ds-grid__col--'],
    div[class*=' ds-grid__col--'] {
      @include breakpoint(tablet) {
        &:nth-child(even) {
          padding-left: 2.5%;
          padding-right: 0;
        }
        &:nth-child(odd) {
          padding-right: 2.5%;
          padding-left: 0;
        }
      }

      @include breakpoint(laptop-and-up) {
        &:nth-child(even) {
          padding-left: 6%;
          padding-right: 0;
        }
        &:nth-child(odd) {
          padding-right: 6%;
          padding-left: 0;
        }
      }
    }

    @media screen and (max-width: $breakpoint-m-max-width) {
      div[class^='ds-grid__col--'],
      div[class*=' ds-grid__col--'] {
        padding: 0;
      }
    }

    @media screen and (min-width: $breakpoint-m) {
      .roaming-usage-more-details,
      .local-usage-more-details {
        padding-left: 0 !important;
      }
    }
  }

  .ds-icon--size-s {
    height: 12px;
    margin-right: 4px;
    width: 12px;
  }
}

.of-subscription-details__add-on {
  &__mobileid-icon {
    &__active {
      background: transparent url('../../common/svg/mobiilivarmenne-logo.svg') center no-repeat;
      height: 40px;
      width: 40px;
    }

    &__disabled {
      background: transparent url('../../common/svg/mobiilivarmenne-logo-smoke.svg') center no-repeat;
      height: 40px;
      width: 40px;
    }
  }

  &__mobileid {
    &__active {
      color: var(--eds-color-grey-700);
    }

    &__disabled {
      color: var(--eds-color-grey-400);
    }
  }

  &__row-action,
  &__row-label {
    display: inline;
  }

  &__row-action-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    span {
      line-height: 18px;
    }

    button {
      margin-top: 2.5rem;
      max-width: 300px;
    }

    @media only screen and (max-width: $breakpoint-m) {
      flex-direction: column;
      justify-content: center;
      justify-items: center;
    }
  }
}

.of-mobileid-confirmation__text-bold {
  padding-top: $global-baseline;
}
