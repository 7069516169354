.article-body {
  width: 100%;
  box-sizing: border-box;

  /* STYLES SPECIFIC TO MIGRATED ARTICLES BELOW */

  /*
  Any classes that start with "from-wordpress-" are required to preserve the layout of articles that
  were migrated over from the previous WordPress setup. The original HTML structure for many migrated articles
  is quite messy, and as a result many of the related CSS selectors get quite complicated as well.
  */
  .from-wordpress-panel-grid {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &.from-wordpress-panel-grid-no-style {
      display: block;
    }

    .from-wordpress-panel-grid-cell {
      &:first-child {
        flex: 3;
      }

      &:nth-child(2) {
        flex: 2;
        figure {
          width: auto !important;
        }
        div {
          div {
            div {
              figure {
                float: left;
                margin-inline-start: 30px;
                margin-inline-end: 30px;
              }
            }
          }
        }
      }
    }
  }

  /* 
      Some lists in WordPress articles have created left "padding"
      by nesting multiple lists inside each other and then removing any list styles
      for the parent lists. Doing the same thing here.
    */
  ul.from-wordpress-nested-list:has(ul) {
    list-style: none;
    padding-left: 0;
  }

  ol.from-wordpress-nested-list:has(ol) {
    list-style: none;
    padding-left: 0;
  }

  // The "leaf" (innermost) list
  .from-wordpress-nested-list ul {
    list-style-type: disc;
    padding-left: 32px;
  }

  p {
    // Some migrated articles have images or other things inside <p> tags. Need this definition or they will be incorrectly sized.
    width: 100%;
  }

  .from-wordpress-right-align {
    float: right;
  }
}
