@import '../../common/style/styleguide';

.of-minute-based-subscriptions {
  margin: 0 calc(8 * var(--eds-baseline)) 0 var(--eds-baseline);

  .ds-dropdown {
    max-width: calc(50 * var(--eds-baseline));
    margin-bottom: var(--eds-baseline);
  }
}
