@import '../../../common/style/styleguide';

.of-device-checkout-delivery-details__pickup {
  .ds-input {
    margin-bottom: calc(2 * var(--eds-baseline));
  }

  .ds-selectgroup__list-item__text {
    flex-direction: column;
    gap: 0;
    text-align: left;
  }
}
