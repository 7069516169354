@import '../../common/style/styleguide';

.of-enrollment-program-consent {
  h5 {
    + div {
      padding-bottom: $global-baseline;
      padding-top: $global-baseline;

      + div {
        padding-top: calc(0.5 * var(--eds-baseline));
      }
    }
  }
}
