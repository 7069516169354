@import '../../common/style/styleguide';

.of-contact-details {
  .of-details-wrapper__hero-grid-item {
    padding-top: calc(2 * var(--eds-baseline));
  }

  h1 {
    margin: 0;
    vertical-align: bottom;
  }

  &__disclaimertext {
    padding-top: $global-baseline * 2.5;
  }

  &__content form div {
    margin-left: 0;
  }

  .of-linkable-accordion:first-of-type {
    margin-top: 40px;
    border-top: 1px solid #e8e8ea;
  }

  &__content .of-contact-form__description dl dt button {
    margin-left: calc(var(--eds-baseline) / 2);
  }

  &__move-contact {
    margin-top: calc(var(--eds-baseline) * 3);
  }

  @media screen and (max-width: 640px) {
    & > div:nth-of-type(2) > div {
      margin: 0;
    }
    &__options {
      display: inline-grid;
      row-gap: var(--eds-baseline);
      width: 100%;
      margin: 0;
      padding-right: 5%;

      div:first-of-type {
        button {
          margin-bottom: calc(3 * var(--eds-baseline));
          width: 49%;
          padding-left: var(--eds-baseline);
          padding-right: var(--eds-baseline);
        }
      }
    }
    &__content form > div {
      display: block;
    }
    &__editButton {
      width: 49%;
    }
    button.of-contact-details__removeButton {
      width: 49%;
      padding: 0 var(--eds-baseline);
    }
  }

  @media screen and (min-width: 641px) {
    .of-details-wrapper__hero-right {
      max-width: 100%;
    }
    &__content form > div:first-of-type {
      display: inline-block;
    }

    &__options {
      width: min(687px, calc(100vw - 9 * var(--eds-baseline)));
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      div:first-of-type {
        button {
          width: unset;
          padding: 10px 22px;
        }
      }

      div:nth-of-type(2) {
        display: flex;
      }
    }

    &__removeButton {
      width: unset;
    }

    button.of-contact-details__editButton {
      display: block;
      float: none;
      padding-left: calc(6 * var(--eds-baseline));
      padding-right: calc(6 * var(--eds-baseline));
    }
  }

  @media screen and (min-width: 1170px) {
    button.of-contact-details__editButton {
      float: right;
    }

    &__move-contact {
      margin-top: var(--eds-baseline);
    }
  }
}
