@import '../../common/style/styleguide';

.of-order-delivery-options {
  &__container {
    max-width: 1260px;
    margin: 0 auto;
  }

  &__grid {
    margin: 0 5%;
  }

  .ds-grid__row div:not(.of-wizard-actions-container div) {
    flex: 1;
  }

  .ds-grid__col--xl-12 {
    padding: 0;
  }

  &__phaseindicator-container {
    background-color: $ea-color-white-smoke;
    border: 1px solid $ea-color-smoke;
    display: flex;
    justify-content: center;
    margin-bottom: 5 * $global-baseline;
    padding: calc(3 * var(--eds-baseline)) 30px;
  }

  &__section {
    padding-bottom: 5 * $global-baseline;

    &-edit-button {
      padding-top: $global-baseline;
    }
  }

  h3 {
    padding-bottom: $global-baseline;
  }

  .ea-wizard-actions {
    padding-bottom: 3 * $global-baseline;
  }

  .of-create-billing-account {
    max-width: 800px;
  }

  .of-order-summary {
    margin-bottom: 3 * $global-baseline;
  }
}
