@import '../../common/style/styleguide';

.of-login-registration-container {
  display: flex;
  flex-direction: column;
  padding: 0 2 * $global-baseline;

  @include breakpoint(phone) {
    padding-left: var(--eds-baseline);
    padding-right: var(--eds-baseline);
  }

  &__login,
  &__registration {
    padding: 2 * $global-baseline 0;
  }

  &__login {
    border-bottom: 1px solid $ea-color-light-grey;
  }

  &__registration {
    h3 {
      padding-top: 36px;
    }

    @include breakpoint(phone-large-and-down) {
      padding-left: 36px;

      h3 {
        padding-top: 0px;
      }
    }
  }

  @include breakpoint(tablet-and-up) {
    flex-direction: row;
    padding: 4 * $global-baseline 0;

    &__registration {
      padding: 0 4 * $global-baseline;
    }

    &__login {
      padding: 0 4 * $global-baseline 0 0;
      border-right: 1px solid $ea-color-light-grey;
      border-bottom: none;
    }
  }
}
