@import '../../common/style/styleguide';

.of-add-on-external-link {
  font-size: calc(calc(var(--eds-font-size-baseline) * 0.9));
}

.of-add-on-additional-information {
  font-size: 13px;
  padding: 4px 4px 4px 0;
}
