@import '../../common/style/styleguide';

.of-loading {
  align-items: center;
  display: flex;

  &--p- {
    &b- {
      &0 {
        padding-bottom: 0;
      }
      &1 {
        padding-bottom: calc(0.75 * var(--eds-baseline));
      }
      &2 {
        padding-bottom: calc(1.5 * var(--eds-baseline));
      }
      &4 {
        padding-bottom: calc(3 * var(--eds-baseline));
      }
    }

    &t- {
      &0 {
        padding-top: 0;
      }
      &1 {
        padding-top: calc(0.75 * var(--eds-baseline));
      }
      &2 {
        padding-top: 1.5 + var(--eds-baseline);
      }
      &4 {
        padding-top: calc(3 * var(--eds-baseline));
      }
    }
  }

  .ds-loadingspinner--size-xl {
    margin-bottom: calc(3 * var(--eds-baseline));
    margin-top: calc(3 * var(--eds-baseline));
  }
}
