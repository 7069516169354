@import '../../common/style/styleguide';

.of-catalog-list-container {
  margin: 0 auto;
  max-width: 1260px;

  .of-catalog-list {
    padding: 0 10px;

    .of-table-wrapper .of-table .ds-table--responsive tbody tr td {
      overflow: hidden;
    }

    @media only screen and (min-width: $breakpoint-l) {
      padding: 0 55px;

      .of-table-wrapper table {
        table-layout: fixed;
      }
    }

    .of-all-accounts-toggle {
      margin-top: calc(var(--eds-baseline) * 2);

      @media only screen and (min-width: $breakpoint-s) {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-l) and (max-width: $breakpoint-xl) {
  .of-catalog-list-container .of-catalog-list {
    th button {
      white-space: normal;
      text-align: left;
    }
  }
}
