@import '../../common/style/styleguide';

@mixin breadcrumb-dynamic-margins {
  @media only screen and (min-width: $breakpoint-s) {
    margin-left: 32px;
  }

  @media only screen and (min-width: $breakpoint-l) {
    margin-left: 40px;
  }

  @media only screen and (min-width: $breakpoint-xl) {
    margin-left: 48px;
  }
}

// Use design-system spacing with multiples of eight: 8, 16, 24, 32, 40, 48, 56, 64, 72, 80…
.of-breadcrumbs {
  list-style: none;
  font-size: 14px;
  margin: calc(2 * var(--eds-baseline));
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include breadcrumb-dynamic-margins;

  /*
    For mobile devices, we have a specific way of truncating the breadcrumb paths once there are
    at least 4 path segments (excluding the current page title). In those cases, we leave only the first
    and the last path segment, replacing the middle ones with three dots.

    E.g.
    Breadcrumb path        : Yrityksille / Liittymät / Nettiliittymät / Netti Lite / Saatavuus
    Will show on mobile as : Yrityksille / ... / Netti Lite / Saatavuus

    On devices larger than $breakpoint-m all the paths are always visible.
  */
  li {
    line-height: 20px;
    &:nth-child(2) {
      visibility: hidden;
      letter-spacing: -999px;

      &:before {
        content: '...';
      }

      &:before,
      &:after {
        visibility: visible;
        letter-spacing: normal;
      }
    }
    &:nth-child(3) {
      display: none;
    }

    &:last-child,
    &:nth-last-child(2) {
      visibility: visible;
      letter-spacing: normal;
      &:before {
        content: '';
      }
    }

    &:nth-last-child(2),
    &:last-child {
      display: initial;
    }

    &:after {
      content: '/';
      margin: 0 8px;
    }

    &:last-child {
      &:after {
        content: '';
      }
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media only screen and (min-width: $breakpoint-m) {
      &:nth-child(2) {
        visibility: visible;
        letter-spacing: normal;
        &:before {
          content: '';
        }
      }
      &:nth-child(3) {
        display: initial;
      }
    }
  }

  a {
    cursor: pointer;
    color: #0019af;

    &:hover {
      text-decoration: underline;
    }
  }
}
