@import '../../../common/style/styleguide';

.of-product-details-addon {
  &-border-box {
    margin: 16px 0;
    padding: 16px;
    font-size: 16px;
    border: 1px solid var(--eds-color-grey-300);
    border-radius: 2px;
    background: $ds-color__white;
    color: var(--eds-color-grey-700);
    -ms-flex-align: baseline;
    align-items: baseline;

    .of-product-details-addon-checkbox {
      flex-grow: 1;
      margin: 0;

      .ds-checkbox__icon {
        margin-right: 16px;
      }

      label {
        font-family: var(--eds-header-font-family-bold);
        font-size: 17px;
      }
    }
  }
}
