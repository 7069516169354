@import '../../common/style/styleguide';

.of-hierarchical-selector {
  padding: calc(3 * var(--eds-baseline));
  border: 1px solid var(--eds-color-grey-400);

  hr {
    margin-top: calc(3 * var(--eds-baseline));
    margin-bottom: calc(3 * var(--eds-baseline));
    border-top: 1px solid var(--eds-color-grey-400);
    border-bottom: none;
  }

  &__item,
  &__additional-item {
    display: flex;
    justify-content: space-between;

    input[type='checkbox']:disabled {
      visibility: hidden;
    }

    input[type='checkbox']:disabled,
    label.disabled {
      opacity: 0.4;
    }
  }

  &__spacer {
    border-left: 1px solid var(--eds-color-grey-500);
    margin-top: calc(0.5 * var(--eds-baseline));
    margin-left: calc(1.5 * var(--eds-baseline));
    height: calc(2 * var(--eds-baseline));
  }

  &__spacer:first-child {
    display: none;
  }
}
