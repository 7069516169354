@import '../../common/style/styleguide';

.of-hero-heading {
  background-color: var(--eds-color-grey-100);
  overflow: hidden;
  height: 286px;

  &__home {
    height: 360px;
    background-color: $ds-color__secondary-blue-100;

    .of-hero-heading__wrapper {
      @media only screen and (max-width: $breakpoint-l-max-width) {
        width: $breakpoint-l-max-width;
      }

      @media only screen and (max-width: $breakpoint-m-max-width) {
        width: $breakpoint-m-max-width + 200px;
      }

      @media only screen and (max-width: $breakpoint-s-max-width) {
        width: 100%;
      }
    }
  }

  &__fullscreen {
    .of-hero-heading__wrapper {
      width: inherit;
      max-width: 100%;
    }

    .of-hero-heading__image {
      background-position: center;
      background-size: cover;
    }
  }

  &__centered {
    .of-hero-heading__content {
      align-items: center;
    }
  }

  h1 {
    margin: 0;
    padding: 0;
    font-size: calc(6.5 * var(--eds-baseline));

    @media only screen and (max-width: $breakpoint-l-max-width) {
      font-size: calc(6 * var(--eds-baseline));
    }

    @media only screen and (max-width: $breakpoint-m-max-width) {
      font-size: calc(5 * var(--eds-baseline));
    }

    @media only screen and (max-width: $breakpoint-s-max-width) {
      font-size: calc(4 * var(--eds-baseline));
    }
  }

  .of-hero-breadcrumbs {
    position: absolute;
  }

  &__subtitle {
    font-size: 24px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__wrapper {
    width: $breakpoint-xl;
    overflow: hidden;
    height: 100%;
    margin: 0 auto;

    @media only screen and (max-width: $breakpoint-l-max-width) {
      width: $breakpoint-l-max-width;
    }

    @media only screen and (max-width: $breakpoint-m-max-width) {
      width: $breakpoint-m-max-width;
    }

    @media only screen and (max-width: $breakpoint-s-max-width) {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    max-width: 1260px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 5%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media only screen and (max-width: $breakpoint-s-max-width) {
      align-items: center;
    }
  }

  &__image {
    background-position: bottom right;
    background-size: auto 90%;
    background-repeat: no-repeat;

    @media only screen and (max-width: $breakpoint-s-max-width) {
      background-size: 320px 110px;
    }

    &--selfservice-home {
      background-image: url('../../common/svg/hero_general.svg');
    }
    &--company-info {
      background-image: url('../../common/svg/hero_company.svg');
    }
    &--customer-orders {
      background-image: url('../../common/svg/hero_orders.svg');
    }
    &--invoices {
      background-image: url('../../common/svg/hero_invoicing.svg');
    }
    &--messaging-center {
      background-image: url('../../common/svg/hero_general.svg');
    }
    &--subscriptions {
      background-image: url('../../common/svg/hero_products.svg');
    }

    &--employee-devices {
      background-image: url('../../common/svg/EOE_products.svg');
    }

    &--employee-home {
      background-image: url('../../common/svg/EOE_mainpage.svg');
    }

    &--employee-profile {
      background-image: url('../../common/svg/EOE_details.svg');
    }

    &--employee-orders {
      background-image: url('../../common/svg/EOE_details.svg');
    }

    &--employee-omalasku {
      background-image: url('../../common/svg/EOE_details.svg');
    }

    &--employee-store {
      background-image: url('../../common/svg/EOE_shop.svg');
    }

    &--employee-subscriptions {
      background-image: url('../../common/svg/EOE_products.svg');
    }

    &--employee-broadbands {
      background-image: url('../../common/svg/EOE_products.svg');
    }
  }
}
