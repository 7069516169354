@import '../../../../../../common/style/styleguide';
@import '../../../../../../components/BreadCrumbs/BreadCrumbs.scss';

.of-subscription-selection-nav {
  background-color: var(--eds-color-grey-100);

  nav > ol {
    margin: 0 calc(2 * var(--eds-baseline));
    padding: calc(2 * var(--eds-baseline)) 0;
    @include breadcrumb-dynamic-margins;
  }

  .auto-complete-container {
    margin-bottom: calc(6 * var(--eds-baseline));
    > .autocomplete-root {
      min-height: auto;
      max-width: 320px;
      @include breakpoint(phone-large-and-down) {
        max-width: initial;
      }
    }
  }
}
