@import '../../common/style/styleguide';

// This is in the path file, not in the component...
.of-forgot-password-wrapper {
  &__content {
    margin-bottom: calc(5 * var(--eds-baseline));
    position: relative;
  }
}

#of-forgot-password {
  box-sizing: border-box;
  display: block;
  float: none;
  line-height: calc(3 * var(--eds-baseline));
  margin: 0 auto;
  // Random number that doesn't spread the content too wide
  max-width: 600px;
  padding: calc(3 * var(--eds-baseline)) calc(2.5 * var(--eds-baseline)) 0 calc(2.5 * var(--eds-baseline));

  @include breakpoint(phone-large-and-up) {
    padding: calc(3 * var(--eds-baseline)) calc(3 * var(--eds-baseline)) 0 calc(3 * var(--eds-baseline));
  }

  .of-forgot-password-step {
    &__header {
      margin: calc(5 * var(--eds-baseline)) 0 calc(3 * var(--eds-baseline)) 0;
    }
  }

  .of-modal__buttons + a {
    display: block;
    margin-top: calc(var(--eds-baseline) * 3);
  }
}
