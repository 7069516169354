@import '../../common/style/styleguide';

.of-addon-management-buttons {
  margin: calc(var(--eds-baseline) * 4) 0;
  &__view {
    display: flex;
    justify-content: flex-end;
  }
  &__edit {
    display: flex;
    justify-content: flex-start;
  }
}
