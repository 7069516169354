@import '../../../../common/style/styleguide';

.of-dns-domain-list {
  margin-top: calc(var(--eds-baseline) * 2);
  margin-bottom: calc(var(--eds-baseline) * 2);

  .ds-disclaimer .ds-disclaimer-content a {
    margin-top: calc(var(--eds-baseline) * 2);
    color: unset;
    text-decoration: none;
  }
}
