@import '../../common/style/styleguide';

.of-invoice-details {
  &__accounts {
    small,
    span {
      display: block;
    }
    small {
      padding-top: calc(1.5 * var(--eds-baseline));
      line-height: 15px;
    }
  }

  &__actions {
    padding-bottom: calc(1.5 * var(--eds-baseline));
    padding-top: calc(6 * var(--eds-baseline));

    a {
      display: inline-block;
    }
    button,
    a {
      margin-bottom: calc(1.5 * var(--eds-baseline));
      margin-right: 2.75em;
      text-align: left;
    }
    .ea-icon {
      margin-right: 1.25rem;
      vertical-align: middle;
    }
  }

  .of-open-cases-notification {
    min-width: calc(35 * var(--eds-baseline));
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 4px;
  }
}
