@import '../../../common/style/styleguide';

.of-mfa-enable-confirmation {
  padding: calc(var(--eds-baseline) * 3);

  .picto-lock {
    margin: 0 0 var(--eds-baseline) 0;
    height: calc(var(--eds-baseline) * 8);
    width: calc(var(--eds-baseline) * 8);
    background: transparent url('../../../common/svg/picto-lock.svg') left top no-repeat;
  }
}
