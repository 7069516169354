@import '../../common/style/styleguide';

.of-device-checkout-cart-product {
  padding: calc(3 * var(--eds-baseline)) 0;

  .of-link {
    cursor: pointer;
    text-decoration: none;
  }

  &__additional-field {
    width: 400px;
    @include breakpoint(tablet-and-down) {
      width: 100%;
    }
  }

  // this resembles ea-fgrid tablet-push-1
  .grid-push-1 {
    margin-left: 9.33%;
  }

  .ds-accordion__section,
  .ds-accordion__section__content {
    overflow: visible;
    height: unset !important;
  }

  .ds-accordion__section__content .ds-contentWrapper {
    padding: 0;
  }

  .bottom-row {
    text-align: left;
    margin-bottom: calc(0.75 * var(--eds-baseline));

    @include breakpoint(tablet-and-up) {
      margin-bottom: 0;

      .left-column {
        float: left;
      }
    }
  }

  &__addOn-details {
    border-top: 1px solid var(--eds-color-grey-400);
    padding-bottom: calc(1.5 * var(--eds-baseline));
    padding-top: calc(1.5 * var(--eds-baseline));

    &--price {
      @include breakpoint(tablet-and-up) {
        text-align: right;
      }
    }

    &--remove {
      padding-top: calc(1.5 * var(--eds-baseline));
    }
  }

  &__details {
    font-size: 13px;

    &--price {
      margin: 0;
      padding-bottom: var(--eds-baseline);

      @include breakpoint(tablet-and-up) {
        text-align: right;
      }
    }

    &--disclaimer {
      @include breakpoint(tablet-and-up) {
        text-align: right;
      }
    }

    &--quantity {
      font-weight: normal;
      @include breakpoint(tablet-and-up) {
        float: right;
      }
    }
  }

  .ds-quantity {
    @include breakpoint(tablet-and-up) {
      float: right;
    }
  }

  .of-enrollment-program-consent {
    .of-label-with-tooltip {
      width: auto;
    }
  }

  &__additional-detail {
    border: 1px solid var(--eds-color-grey-400);
    margin-top: calc(3 * var(--eds-baseline));
    padding: calc(var(--eds-baseline) * 3) calc(var(--eds-baseline) * 3) var(--eds-baseline);

    @include breakpoint(tablet-and-up) {
      padding: calc(4.5 * var(--eds-baseline)) calc(4.5 * var(--eds-baseline)) calc(3 * var(--eds-baseline));
    }

    &-header {
      padding-bottom: calc(0.75 * var(--eds-baseline));
    }

    .of-sim-card-selection {
      padding-top: calc(0.75 * var(--eds-baseline));
    }
  }

  .of-sales-product-additional-parameters {
    > h5 {
      margin: calc(1.5 * var(--eds-baseline)) 0 var(--eds-baseline) 0;
    }

    #number-privacy {
      padding-bottom: calc(1.5 * var(--eds-baseline));
      padding-top: calc(0.75 * var(--eds-baseline));
    }
  }
}
