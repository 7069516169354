@import '../../common/style/styleguide';

.of-mobile-broadband-four-g-selection {
  margin: calc(var(--eds-baseline) * 4);
  @media only screen and (min-width: $breakpoint-m) {
    margin: calc(var(--eds-baseline) * 6) calc(var(--eds-baseline) * 8) 0 var(--eds-baseline);
  }

  &__dropdown {
    max-width: 400px;
  }

  &__description {
    &__price {
      padding: calc(var(--eds-baseline) * 3) 0;
      &__final {
        color: $ds-color__blue-600--light;
        font-family: var(--eds-header-font-family-bold);
        font-size: calc(var(--eds-baseline) * 4);
      }
      &__before-discount {
        font-family: var(--eds-header-font-family);
        font-size: calc(var(--eds-baseline) * 3);
        padding-left: calc(var(--eds-baseline) * 2);
        text-decoration: line-through;
      }
    }

    &__footer {
      padding-bottom: calc(var(--eds-baseline) * 7);
      span {
        font-size: calc(var(--eds-baseline) * 1.7);
        display: block;
      }
    }
  }
}
