@import '../../common/style/styleguide';

.of-navigation-menu {
  display: flex;
  flex-wrap: wrap;
  padding: calc(2 * var(--eds-baseline)) 0;

  &__item {
    cursor: pointer;
    display: block;
    text-align: center;
    flex-basis: 50%;
    padding-top: var(--eds-baseline);

    @include breakpoint(tablet-and-up) {
      flex-basis: 33.3%;
    }

    @include breakpoint(laptop-and-up) {
      flex-basis: initial;
      flex: 1 0 auto;
    }

    @include breakpoint(desktop) {
      padding: calc(2 * var(--eds-baseline)) 0;
    }
  }

  &__link {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: var(--eds-baseline);

    @include breakpoint(desktop) {
      flex-direction: row;
    }
  }

  &__icon {
    display: block;
    padding-bottom: 4px;
    text-align: center;

    @include breakpoint(desktop) {
      padding-bottom: 0;
      padding-right: var(--eds-baseline);
    }
  }

  &__text {
    display: block;
    padding-bottom: var(--eds-baseline);
  }
}
