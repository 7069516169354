@import '../../common/style/styleguide';

$of-table-font-size: 14px;

.of-table-wrapper {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;

  &.empty-table {
    padding-bottom: 32px;
    margin-bottom: -32px;
  }

  .no-results {
    margin-top: 24px;
    margin-bottom: 64px;

    strong {
      font-weight: 600;
    }
  }

  .ds-table--striped tbody tr:nth-child(odd) th,
  .ds-table--striped tbody tr:nth-child(odd) td:has(> div.loading-row-item) {
    background-color: var(--eds-color-black--dark);
  }

  div.loading-row-item {
    background-color: var(--eds-color-grey-200);
    padding: 18px 16px;
  }

  .ds-description__list-item-title {
    line-height: 20px;
    font-family: var(--eds-base-font-family);
    font-size: $of-table-font-size;
    font-weight: var(--eds-font-weight-base);
    margin: 0;
  }

  .ds-description__list-item {
    margin: 0;
  }

  .ds-description__list-item-data {
    line-height: 16px;
    font-size: 12px;
  }

  .ds-table tr td {
    position: relative;
  }

  .of-table-header-dropdown {
    margin: -10px auto;

    ul {
      max-height: 150px;
      li {
        font-weight: 400;
      }
    }

    button {
      font-weight: 700;
      font-size: $of-table-font-size;
    }
  }

  .of-table-scroll-hint {
    visibility: visible;
  }

  .of-table {
    height: 100%;
    width: 640px;
    display: inline-block;

    .ds-table {
      width: 100%;
      &--responsive {
        thead {
          tr {
            border-bottom: 1px solid;
            th,
            td {
              display: table-cell;
              margin: auto;
              clip: unset;
              clip-path: none;
              height: 40px;
              position: static;
              width: auto;
              white-space: nowrap;
              text-align: left;
              padding: 10px 16px;
            }
          }
        }
        tbody {
          tr {
            display: table-row;
            td {
              display: table-cell;
              font-size: $of-table-font-size;
              line-height: 20px;
              padding: 10px 16px;
            }
            td::before {
              content: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-m) {
  .of-table-wrapper {
    .of-table {
      .ds-table {
        &--responsive {
          border-collapse: collapse;
          thead {
            tr {
              border-bottom: solid 1px;
              th,
              td {
                -webkit-clip-path: none !important;
                clip-path: none !important;
                padding: 10px 16px !important;
                position: static !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 746px) {
  .of-table-wrapper {
    .of-table-scroll-hint {
      visibility: hidden;
    }

    .of-table {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 960px) {
  .of-table-wrapper {
    .of-table {
      .ds-table {
        .ds-table--responsive tbody tr {
          display: table-cell;
        }
      }
    }
  }
}

.of-table__input-bar {
  width: 100%;
  margin-top: calc(var(--eds-baseline) * 2);
  margin-bottom: calc(var(--eds-baseline) * 2);

  @media only screen and (min-width: $breakpoint-m) {
    margin-bottom: 0;
  }
}
