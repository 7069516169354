@import '../../common/style/styleguide';

$gap: calc(3 * var(--eds-baseline));

div.of-subscription-card {
  border-width: 2px;
  display: flex;
  flex-direction: column;
  // We want to force the footer's button to the bottom of the parent
  justify-content: space-between;
  text-align: center;

  h2 {
    margin-top: $gap;

    > div {
      margin-bottom: $gap;
    }
  }

  hr {
    margin: 0;
    width: 100%;
  }

  .of-subscription-card__button {
    display: flex;
    align-items: center;

    button.ds-button {
      padding-left: 25px;
      padding-right: 25px;
      @include breakpoint(laptop-and-down) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }

  .of-subscription-card__bullets {
    padding-left: calc(var(--eds-baseline) * 0.5);
    padding-top: $gap;
    text-align: left;

    .ds-icon {
      margin-right: 0.8em;
      min-width: calc(2 * var(--eds-baseline));
    }
  }

  .of-subscription-card__icongrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.1em;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding-left: 0;

    li {
      display: flex;
      // Align icon with the first line of text if the text is divided to multiple lines
      align-items: flex-start;

      .ds-icon {
        margin-right: 0.4em;
      }

      span {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  .of-subscription-card__pill {
    border: 3px solid black;
    border-radius: calc(2 * var(--eds-baseline));
    font-weight: 700;
    height: calc(4 * var(--eds-baseline));
    line-height: calc(3.2 * var(--eds-baseline));
    margin: 0 auto;
    padding: 0 calc(var(--eds-baseline) * 0.8);
    width: min-content;
  }

  .of-subscription-card__price {
    height: 135px;
  }

  .of-subscription-card__price {
    &__original-price {
      text-align: left;
      font-weight: normal;
      font-style: normal;
      font-size: 20px;
      font-family: 'Open Sans', sans-serif;
      line-height: 20px;
    }

    &__text-and-button {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
    }

    .ds-price-additional {
      margin-top: calc(2 * var(--eds-baseline));
      text-align: start;
      display: flex;
      flex-wrap: wrap;
    }

    .ds-price-content {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &-preamble {
      font-family: var(--eds-base-font-family);
      margin-bottom: 0.25em;
      margin-right: 0.5em;
    }

    &-unit {
      font-family: var(--eds-base-font-family);
      font-size: $global-baseline * 1.8;
      font-weight: 600;
      @include breakpoint(laptop-and-down) {
        font-size: $global-baseline * 1.2;
      }
    }

    del {
      color: var(--eds-color-grey-500);
    }
  }

  .ds-price-content-number:first-child {
    margin-left: 0.1em;
  }

  .of-subscription-card__specs {
    padding-top: $gap;
  }

  .ds-card {
    /* This is just a prefix for the classes below, don't add styles here */
    &-badge {
      left: auto;
      right: 0;
      top: 0;
      transform: none;
    }

    &-content {
      flex-grow: 1;
      padding-bottom: $gap;
      padding-top: 0;

      hr {
        margin-top: $gap;
      }

      // Use higher specificity than the previous hr element to be able to override it
      .no-margin {
        margin: 0;
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      .ds-button {
        border-radius: 4px;
      }
    }

    &-heading {
      flex-grow: 0;

      hr {
        margin-top: $gap;
      }

      padding-left: unset;
      padding-right: unset;
    }
  }

  .ds-card-heading {
    .ds-h4 {
      @include fluid-padding($ds-baseline * 3, $ds-baseline * 4, 'right');
      @include fluid-padding($ds-baseline * 3, $ds-baseline * 4, 'left');
    }
  }

  &--highlight-blue {
    border-color: $ds-color__blue-600;
  }

  &--highlight-orange {
    border-color: $ds-color__orange-700;
  }

  &--highlight-turquoise {
    border-color: $ds-color__turquoise-400;
  }

  &--button {
    @include breakpoint(desktop) {
      min-width: 335px;
    }

    margin-top: 8px;

    &--icon {
      @mixin speed-meter-style($name) {
        height: 45px;
        width: 45px;
        background: transparent url('../../common/svg/#{$name}.svg') center / contain no-repeat;
      }

      &--speed-meter-XL {
        @include speed-meter-style('speed-meter-XL');
      }

      &--speed-meter-L {
        @include speed-meter-style('speed-meter-L');
      }

      &--speed-meter-M {
        @include speed-meter-style('speed-meter-M');
      }

      &--speed-meter-S {
        @include speed-meter-style('speed-meter-S');
      }

      &--speed-meter-XS {
        @include speed-meter-style('speed-meter-XS');
      }
    }

    > button {
      padding: 5px 10px;
      margin: var(--eds-baseline);
      border: solid 1px $ds-color-_neutral-400;
      background-color: $ds-color__white;
      color: var(--eds-color-grey-700);
    }

    > button:hover {
      background-color: var(--eds-color-grey-200);
      border-color: $ds-color-_neutral-400;
    }

    > button.selected {
      border: solid 2px $ea-color-linkblue;
    }

    &--text {
      text-transform: none;
      text-align: left;
      font-size: 14px;
    }
  }

  &--service {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 1190px) {
      flex-direction: column;
    }
    @media screen and (max-width: 878px) {
      flex-direction: row;
    }
    @media screen and (max-width: 775px) {
      flex-direction: column;
    }

    &--column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;

      span.small {
        font-size: 12px;
      }

      .popover {
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
    }

    &--items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &--item {
        display: flex;
        align-items: center;
        margin: 1px 0;

        div.icon {
          margin-right: 5px;

          @mixin icon-style($name) {
            height: 18px;
            width: 18px;
            background: transparent url('../../common/svg/#{$name}--light.svg') center / contain no-repeat;
          }

          &--roaming {
            @include icon-style('roaming');
          }

          &--voicemail {
            @include icon-style('voicemail');
          }

          &--mobiiliturva {
            @include icon-style('mobiiliturva');
          }

          &--yrityswifilite {
            @include icon-style('network');
          }

          &--mobile-broadband {
            @include icon-style('mobile-broadband');
          }

          &--managed-service {
            @include icon-style('managed-service');
          }
        }

        &.disabled {
          opacity: 0.4;
          border-color: var(--eds-color-grey-300);
          pointer-events: none;
        }
      }
    }
  }

  &--specification {
    &--disabled > * {
      color: var(--eds-color-grey-400);
    }

    &--word-separator {
      border: solid 0 var(--eds-color-grey-300);
      border-bottom-width: 1px;
      margin: 0 5px 3px 5px;
      justify-content: space-between;
      flex-grow: 1;
    }

    &--start {
      display: flex;
      align-items: flex-start;
      align-self: flex-end;
      text-align: left;
      max-width: 90%;
    }

    &--end {
      display: flex;
      text-align: end;
      align-items: flex-end;

      @include breakpoint(phone-large-and-down) {
        max-width: 150px;
      }
    }
  }

  &--voucher {
    padding: 5px 12px;
    background-color: $ds-color__orange-light;
    line-height: 12px;
    margin: -24px 0 -5px 15px;
    font-size: 14px;
    font-weight: var(--eds-font-weight-semibold);
    display: flex;
    width: fit-content;
    border-radius: 2px;
  }

  &--no-voucher {
    line-height: 12px;
    margin-bottom: -5px;
  }

  &--selected-footer {
    background-color: #e3e0dc;
  }

  &--card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    div.selection-footer-ribbon {
      line-height: 28px;
      background-color: $ds-color__blue-600;
      color: $ds-color__white;
      margin-top: -28px;
      width: 100%;
      font-weight: var(--eds-font-weight-semibold);
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      border-radius: 0 0 4px 4px;

      span.spacer {
        width: 5px;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &--footer-disclaimer {
    text-align: center;
    margin-bottom: calc(var(--eds-baseline) * 2);
    font-size: 15px;
  }

  &--content {
    height: 100%;
    display: flex;
    flex-direction: column;

    &--option {
      &-a,
      &-c {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
      }
    }
  }

  &--header {
    > div {
      padding-top: var(--eds-baseline);
    }

    &__main {
      > h2 {
        font-family: var(--eds-header-font-family);
        font-weight: var(--eds-font-weight-base);
        font-size: calc(var(--eds-baseline) * 3.5);
        margin: 0;
      }
    }

    &__description {
      font-family: var(--eds-base-font-family);
      font-weight: var(--eds-font-weight-base);
      font-size: calc(var(--eds-baseline) * 2);
      padding: 0 calc(var(--eds-baseline) * 3.5);
    }
  }

  &--five-g-info {
    text-align: left;

    &__features {
      font-size: calc(var(--eds-baseline) * 1.75);
    }
  }

  .of-subscription-card__header-badge {
    border-radius: 25px;
    font-size: calc(var(--eds-baseline) * 2);
    font-weight: var(--eds-font-weight-semibold);
    line-height: 1;
    padding: 5px 15px 6px;
    background-color: #aeebdf;
  }
}
