@import '../../common/style/styleguide';

.of-case-details {
  h1 {
    margin: 0;
    vertical-align: bottom;
  }

  .of-background-color--light-orange {
    background-color: $ea-color-light-orange;
    color: $ds-color__blue-800;
  }

  .ds-background-color--orange {
    color: $ds-color__white;
  }

  .status-data {
    padding: 2px 10px;
  }

  h2 {
    margin-bottom: 24px;
    margin-top: 0;
  }

  .header {
    margin-left: 36px;
  }

  .header-with-bg-color {
    position: relative;
    background-color: var(--eds-color-grey-100);

    .title-h1 {
      margin-top: 0;
      margin-bottom: 34px;
    }

    .title-h3 {
      padding: 0;
      margin-bottom: 16px;
    }
  }

  .top-grid-block {
    width: max-content;
  }

  .content:first-of-type {
    padding-top: 66px;
  }

  .content {
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    padding-left: 24px;
    padding-right: 24px;
  }

  .top-grid {
    display: grid;
    grid-column-gap: 27px;
    grid-row-gap: 0;
    grid-template-rows: repeat(6, 1fr);
    border-bottom: 1px solid var(--eds-color-grey-300);
    padding-bottom: 23px;
  }

  .top-grid h4,
  .description h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .bottom-grid {
    display: grid;
    grid-column-gap: 27px;
    grid-row-gap: 0;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto 1fr auto;
    margin-bottom: 49px;
    margin-top: 28px;
  }

  .stepper-block {
    border-top: 1px solid var(--eds-color-grey-300);
    padding-top: 35px;
    padding-bottom: 15px;
    padding-right: 20px;

    .ds-stepper ol li.ds-stepper__step {
      margin-left: 0;
    }

    .stepper-header {
      padding-bottom: 24px;
    }
  }

  .description {
    padding-bottom: 15px;

    h2 {
      margin-bottom: 24px;
      margin-top: 0;
    }

    .description-header {
      padding-bottom: 24px;
    }
  }

  hr {
    height: 1px;
    background-color: var(--eds-color-grey-300);
    border: none;
  }

  .content-with-line-breaks {
    white-space: pre-wrap;
  }

  .show-more-button {
    background-color: white !important;
  }

  .notification-wrapper {
    border: solid 1px var(--eds-color-grey-300);
    background-color: var(--eds-color-grey-100);

    .ds-icon {
      flex-shrink: 0;
    }
  }

  .description-block > div {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  @media only screen and (min-width: $breakpoint-s) {
    .content {
      padding-left: 34px;
      padding-right: 34px;
    }
  }

  @media only screen and (min-width: $breakpoint-m) {
    .top-grid {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    .description-block {
      max-width: 660px;
    }
    .stepper-block {
      border-top: 0;
      padding-top: 0;
    }
    .bottom-grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, 1fr);
      margin-bottom: 64px;
      margin-top: 28px;
    }
    .bottom-grid > .stepper-block {
      grid-row: 1;
    }

    .notification-wrapper {
      margin-bottom: auto;
    }
  }

  @media only screen and (min-width: $breakpoint-xl) {
    .top-grid {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr;
    }
    .content {
      max-width: 1100px;
    }
    .stepper-block {
      border-right: 1px solid var(--eds-color-grey-300);
    }
  }
}
