@import '../../common/style/styleguide';

.of-toggle-checkbox {
  border: 1px solid var(--eds-color-grey-300);
  display: flex;
  flex-direction: column;

  &__container {
    &__basic,
    &__additional-info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    // If there is only price, the texts will fit to a smaller space than with additional info (badge / additional texts)
    &__basic {
      @media only screen and (min-width: $breakpoint-s) {
        flex-direction: row;
      }
    }
    &__additional-info {
      @media only screen and (min-width: $breakpoint-m) {
        flex-direction: row;
      }
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    & > .ds-checkbox {
      margin: 0;
    }
  }

  &__price {
    &__basic,
    &__additional-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: flex-start;
    }
    &__basic {
      padding-left: $ds_baseline * 4;
      min-width: calc(8 * var(--eds-baseline));
      @media only screen and (min-width: $breakpoint-s) {
        align-items: flex-end;
        padding: 0;
      }
    }
    &__additional-info {
      flex-basis: 35%;
      padding: $ds_baseline 0 $ds_baseline $ds_baseline * 4;
      @media only screen and (min-width: $breakpoint-m) {
        align-items: flex-end;
        padding: 0;
      }
      &__additional-text {
        text-align: right;
      }
    }
  }

  &__content {
    transition: max-height 0.5s cubic-bezier(0.82, 0, 0.1, 1);
    overflow: hidden;

    &__open {
      display: block;
    }

    &__closed {
      display: none;
    }
  }
}
