@import '../../common/style/styleguide';

.of-pbx-work-hours {
  @include breakpoint(phone) {
    padding-left: calc(0.5 * var(--eds-baseline));
    padding-right: calc(0.5 * var(--eds-baseline));
  }

  &__time_range {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    @include breakpoint(phone-large-and-down) {
      padding-bottom: calc(2 * var(--eds-baseline));
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 50%;
      min-width: 130px;
      @include breakpoint(phone) {
        width: 100%;
      }

      .ds-input--inputarea {
        margin: 0;
        position: relative;
        width: 90%;
        min-width: 130px;
      }
    }
  }
}
