@import '../../common/style/styleguide';

.of-pbx-time-settings {
  padding-bottom: 3 * $global-baseline;
  padding-top: calc(3 * var(--eds-baseline));

  &__item {
    padding-bottom: calc(2 * var(--eds-baseline));
    max-width: calc(60 * var(--eds-baseline));
  }

  &__section-title {
    display: flex;
    align-items: center;
    margin-bottom: var(--eds-baseline);

    .ds-popover-holder {
      margin-left: var(--eds-baseline);
    }
  }

  &__section {
    padding-bottom: calc(6 * var(--eds-baseline));
  }

  &__working-hours > :first-child {
    padding-bottom: calc(1.5 * var(--eds-baseline));
  }

  &__actions {
    width: 100%;
    margin-top: calc(6 * var(--eds-baseline));

    button:first-child {
      margin-right: calc(4 * var(--eds-baseline));
    }
  }

  h4 {
    margin: 0 0 var(--eds-baseline) 0;
  }
}
