@import '../../common/style/styleguide';

.of-subscription-usage-table {
  margin-top: 0;
  margin-bottom: calc(5 * var(--eds-baseline));
  border-bottom: 1px solid var(--eds-color-grey-400);

  &__subtitle {
    font-weight: var(--eds-font-weight-bold);
    padding-top: calc(2 * var(--eds-baseline));
  }

  & &__expandable-row th {
    border-top: 1px solid var(--eds-color-grey-400);
    border-bottom: none;
  }

  & thead tr &__expand-icon {
    width: calc(6 * var(--eds-baseline));
  }

  & thead tr &__data-column {
    width: calc(27 * var(--eds-baseline));
    font-weight: var(--eds-font-weight-base);
  }
}
