@import '../../../common/style/styleguide';

.of-product-details-summary {
  background-color: var(--eds-color-grey-200);
  margin-top: $global-baseline;
  text-align: left;
  padding: 40px;

  h3.ds-h4 {
    font-family: var(--eds-header-font-family-bold);
    padding-bottom: 20px;
    margin: 0;
    border-bottom: 1px solid $ds-color__blue-800;
  }

  &__price {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__add-to-cart {
    width: 100%;
    text-align: center;
    @include breakpoint(tablet-and-up) {
      width: auto;
      text-align: left;
      display: block;
    }
  }
}
