@import '../../common/style/styleguide';

.of-new-support-case {
  display: flex;
  flex-wrap: wrap;
  padding: 0 32px;
  max-width: 640px;
  margin: 0 auto;

  @media screen and (min-width: $breakpoint-xl) {
    max-width: none;
    flex-wrap: nowrap;
    padding: 0;
  }

  .contact-form {
    flex: 100%;
    border-right: 1px solid $ds-color__white;

    @media screen and (min-width: $breakpoint-xl) {
      flex: 50%;
      border-right-color: var(--eds-color-grey-300);
      padding-right: 60px;
    }
  }

  .contact-form {
    h2 {
      margin-top: 0;
      margin-bottom: 40px;
    }
  }

  .info {
    flex: 100%;
    margin: 0 auto;

    @media screen and (min-width: $breakpoint-xl) {
      flex: calc(5 * 100% / 12);
      padding: 0 60px;
    }

    .image {
      width: 288px;
      height: 204px;

      @media screen and (min-width: $breakpoint-s) {
        width: 343px;
        height: 243px;
      }

      @media screen and (min-width: $breakpoint-m) {
        width: 366px;
        height: 260px;
      }

      @media screen and (min-width: $breakpoint-xl) {
        margin: 0 0 16px 0;
      }

      &--noe {
        background: left/contain url('../../common/svg/art-createcase-noe.svg') no-repeat;
      }
      &--public {
        background: left/contain url('../../common/svg/art-createcase-public.svg') no-repeat;
      }
    }
  }
}
