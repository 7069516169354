@import '../../common/style/styleguide';

.of-paid-amount-summary {
  background-color: var(--eds-color-grey-100);
  border: 1px solid var(--eds-color-grey-300);
  padding: calc(1.5 * var(--eds-baseline)) 20px;

  @include breakpoint(tablet-and-up) {
    padding: calc(4.5 * var(--eds-baseline)) 30px;
  }

  &__amount {
    @include breakpoint(tablet-and-up) {
      text-align: right;
    }
  }
}
