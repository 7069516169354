@import '../../common/style/styleguide';

.of-modal__buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: calc(2 * var(--eds-baseline));
  justify-content: flex-end;
  margin-top: calc(4 * var(--eds-baseline));

  @media only screen and (min-width: $breakpoint-s) {
    flex-direction: row;
  }
}
