@import '../../common/style/styleguide';

.of-billing-account-list {
  &__narrow {
    max-width: 100px;
  }

  &__scheduled-timestamp {
    display: flex;

    .ds-icon {
      margin-right: var(--eds-baseline);
    }
  }

  .of-table-wrapper .ds-table tr td {
    > a {
      display: block;
    }

    // External link icon
    .ds-icon {
      display: inline-block;

      svg {
        fill: currentColor;
        height: 12px;
        width: 12px;
      }
    }
  }
}

.of-create-new-billing-account {
  margin-bottom: calc(var(--eds-baseline) * 2);

  @media only screen and (min-width: $breakpoint-m) {
    margin-bottom: 0;
  }
}
