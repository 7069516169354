@import '../../common/style/styleguide';

.of-catalog-products-selection {
  $cartImageSizeLarge: 10 * $global-baseline;
  $cartImageSizeMobile: 7 * $global-baseline;

  .link-button {
    text-transform: none;
    min-height: 0;
    min-width: 0;
    padding: 0;
    font-size: var(--eds-base-font-size);

    .ds-button__content {
      font-size: var(--eds-base-font-size);
    }
  }

  .of-hero-heading {
    @include breakpoint(tablet-and-up) {
      height: 27 * $ds-baseline;
    }
  }

  .ds-accordion__section {
    box-shadow: none;
  }

  .ds-accordion {
    margin-top: 12 * $ds-baseline;
    position: relative;
    right: 140px;
    width: 125%;

    @include breakpoint(laptop) {
      width: 125%;
    }

    @include breakpoint(tablet-and-down) {
      width: 150%;
    }

    @include breakpoint(phone-large-and-down) {
      width: 160%;
    }
  }

  .ds-accordion__section__content-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__border-bottom {
    padding-bottom: var(--eds-baseline);
    border-bottom: 1px solid var(--eds-color-grey-300);
  }

  &__sortandfilter {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;

    padding-right: 0;
    padding-top: 14px;
    padding-left: var(--eds-baseline);

    @include breakpoint(tablet-and-down) {
      padding-left: calc(2 * var(--eds-baseline));
    }

    @include breakpoint(phone-large-and-down) {
      justify-content: flex-start;
      flex-direction: column;
    }

    .ds-input {
      margin: 0;
      @include breakpoint(tablet-and-up) {
        min-width: 370px;
      }
    }

    .ds-dropdown {
      margin: 0;
    }

    .of-product-grid__sort-dropdown div button {
      @include breakpoint(phone-large-and-down) {
        padding-left: 0;
        padding-top: calc(3 * var(--eds-baseline));
      }
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      vertical-align: middle;
    }

    button {
      @include breakpoint(phone-large-and-down) {
        padding-left: 0;
        padding-top: calc(3 * var(--eds-baseline));
      }
      text-transform: uppercase;
      border-width: 0;
      font-size: 14px;
      font-weight: 400;
    }

    button:hover {
      background-color: var(--eds-color-grey-200);
      border-color: $ds-color-_neutral-400;
    }
  }

  &__filter-content {
    .ds-checkbox__label {
      font-size: 14px;
    }

    legend {
      font-weight: bold;
    }

    border-top: 1px solid var(--eds-color-grey-300);
    padding: calc(3 * var(--eds-baseline));

    @include breakpoint(tablet-and-down) {
      width: 100%;
      position: fixed;
      top: 0;
      height: 100%;
      background: #ffffff;
      z-index: 80;
      overflow-y: auto;
    }

    &--mobile {
      display: none;
      @include breakpoint(tablet-and-down) {
        padding-top: var(--eds-baseline);
        width: 100%;
        display: block;
      }
    }

    &__show-all {
      padding-top: var(--eds-baseline);
      cursor: pointer;
      display: block;
    }
  }

  &__table {
    .of-table-wrapper .ds-table .ds-checkbox__label {
      display: flex;
      align-items: flex-end;
      font-size: 14px;
      span {
        margin-right: var(--eds-baseline);
      }
    }

    .of-table-wrapper .of-table .ds-table--responsive tbody tr td {
      align-content: start;
      vertical-align: top;
    }

    th:first-of-type {
      max-width: 130px;
      @include breakpoint(laptop) {
        max-width: 100px;
      }
      @include breakpoint(tablet-and-down) {
        max-width: 50px;
        overflow: visible;
        text-overflow: ellipsis;
      }
    }

    &__related-accessory {
      display: flex;
      padding-top: $ds-baseline;
      margin-top: $ds-baseline;
      width: 100%;
    }

    &__related-accessory_prod-checkbox {
      width: 40px;
    }
    &__related-accessory_prod-text {
      width: 75%;
    }
    &__related-accessory_prod-price {
      width: 15%;
      text-align: end;
      padding-right: $ds-baseline;
    }

    &__images {
      padding-bottom: calc(var(--eds-baseline) * 1.5);
      text-align: center;
      width: $cartImageSizeMobile;
      height: $cartImageSizeMobile;

      @include breakpoint(tablet-and-up) {
        width: $cartImageSizeLarge;
        height: $cartImageSizeLarge;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        display: block;
      }
    }

    &__images_related {
      padding-bottom: $ds-baseline * 1.5;
      text-align: center;
      width: 9 * $ds-baseline;
      height: 9 * $ds-baseline;

      @include breakpoint(tablet-and-up) {
        width: 9 * $ds-baseline;
        height: 9 * $ds-baseline;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        display: block;
      }
    }
  }
}
