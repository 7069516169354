@import '../../../common/style/styleguide';

.of-change-owner-accordion--data-row {
  &:first-child {
    padding-top: calc(3 * var(--eds-baseline));
  }
  padding-bottom: calc(4 * var(--eds-baseline));

  .autocomplete-root {
    min-height: auto;
  }
}
