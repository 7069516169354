@import '../../common/style/styleguide';

.of-device-checkout-cart-product__redeem-or-return-wrapper {
  margin-bottom: calc(1.5 * var(--eds-baseline));

  h4 {
    margin: 0;
    padding-bottom: calc(1.5 * var(--eds-baseline));
  }

  .information-message {
    display: inline-block;
    line-height: 24px;
    vertical-align: top;
    padding-bottom: calc(2.25 * var(--eds-baseline));
  }

  .of-device-checkout-delivery-mode {
    .of-device-checkout-cart-product__redeem-or-return {
      &-disclaimer {
        padding-bottom: calc(3 * var(--eds-baseline));
        padding-top: calc(1.5 * var(--eds-baseline));
      }

      &-information {
        margin-top: -2 * $global-baseline;
        margin-bottom: 2 * $global-baseline;
        color: var(--eds-color-grey-600);
      }
    }
  }
}
