@import '../../common/style/styleguide';

.subscription-selection-header {
  border-bottom: 1px solid var(--eds-color-grey-300);
  margin: 0 var(--eds-baseline) calc(var(--eds-baseline) * 5);
  > h4 {
    margin-bottom: 0;
    padding-bottom: calc(var(--eds-baseline) * 3);
  }
}
