@import '../../common/style/styleguide';

.of-self-service-home {
  &__content {
    background: var(--eds-color-grey-200);
    padding: $global-baseline * 2 $global-baseline;
    &__block {
      background: $ds-color__white;
      max-width: 1260px;
      margin: $global-baseline * 2 auto;
      padding: $global-baseline * 2 $global-baseline $global-baseline * 4 $global-baseline;

      @media screen and (min-width: $breakpoint-l) {
        padding: $global-baseline * 2 $global-baseline * 6 $global-baseline * 4 $global-baseline * 6;
      }
    }
    .contact-info {
      background: var(--eds-color-grey-200);
      display: grid;
      gap: 2 * $global-baseline;
      padding: 0;
      grid-template-columns: repeat(1, 1fr);
      @media screen and (min-width: $breakpoint-m) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
