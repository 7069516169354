@import '../../common/style/styleguide';

.of-fixed-bb-yritystupla {
  border-left: 1px solid var(--eds-color-grey-300);
  border-right: 1px solid var(--eds-color-grey-300);

  &__accordion-header {
    width: 100%;
  }

  &__header-button {
    background: transparent;
    border: none;
    padding: 0;
  }

  &__price {
    margin: 10px 0;

    &-amount-wrapper {
      flex-direction: column;
      line-height: 2rem;
    }
  }

  &__radio {
    box-sizing: content-box;

    &-label,
    > label {
      width: 100%;
    }
  }
}
