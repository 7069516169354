@import '../../common/style/styleguide';

$of-blank-heading-padding-top: 88px;
$of-blank-heading-padding-top-subtitle: 72px;
$of-blank-heading-padding-top-details: 30px;

.of-blank-heading {
  position: relative;
  height: 186px;
  background-color: var(--eds-color-grey-100);

  @media screen and (min-width: $breakpoint-s) {
    height: 226px;
  }

  @media screen and (min-width: $breakpoint-s) {
    .details-top {
      padding-left: 34px;
    }
  }

  .of-details-top {
    display: flow-root;

    > nav.of-public-breadcrumbs-container {
      max-width: calc(100% - 20px);
      position: relative;
      left: 20px;

      @include breakpoint(laptop) {
        left: 28px;
      }

      @include breakpoint(desktop) {
        left: 38px;
      }
    }
  }

  .titles {
    max-width: 1100px;

    margin: 0 auto;

    &--title-only {
      padding: $of-blank-heading-padding-top 24px 0;
    }

    &--with-subtitle {
      padding: $of-blank-heading-padding-top-subtitle 24px 0;
    }

    &--with-details-top {
      padding: $of-blank-heading-padding-top-details 24px 0;
    }

    @media screen and (min-width: $breakpoint-s) {
      &--title-only {
        padding: $of-blank-heading-padding-top 34px 0;
      }

      &--with-subtitle {
        padding: $of-blank-heading-padding-top-subtitle 34px 0;
      }

      &--with-details-top {
        padding: $of-blank-heading-padding-top-details 34px 0;
      }
      margin: 0 auto;
    }

    .title {
      margin-top: 8px;

      @media screen and (min-width: $breakpoint-s) {
        margin-top: 8px;
      }

      &--only {
        margin-top: 0;
      }
    }
  }
}
