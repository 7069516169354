@use 'sass:math';
@use '../../common/style/global-mixins';
@import '../../common/style/styleguide';

.of-details-heading {
  background-color: var(--eds-color-grey-100);
  border: 1px solid var(--eds-color-grey-100);
  position: relative;

  $picto-size: 92px;

  &__main-heading {
    margin: 0 0 4px 0;
  }

  .of-details-heading__title {
    align-items: center;
    display: flex;
    flex-direction: column;
    @include global-mixins.page-margins-as-margin;
    margin-bottom: calc(8 * var(--eds-baseline));
    margin-top: calc(4 * var(--eds-baseline));

    .of-details-heading__hero-picto {
      background-position: center;
      height: $picto-size;
      margin: 0 0 $global-baseline 0;
      min-height: $picto-size;
      min-width: $picto-size;
      width: $picto-size;
    }

    .of-details-heading__hero-right {
      display: inline-block;
      text-align: center;
      vertical-align: top;

      h2 {
        margin-bottom: 0;
        margin-top: 0;

        div {
          padding-bottom: 0;
        }
      }
    }

    .of-details-heading__hero-right-top {
      margin-bottom: var(--eds-baseline);
      margin-top: 0;
    }

    @include breakpoint(tablet-and-up) {
      flex-direction: row;
      .of-details-heading__hero-right {
        text-align: start;
      }
      .of-details-heading__hero-picto {
        // Pictos seem to include margin on the left. We remove that with
        //   negative margin so we can optically align them with the left edge
        //   of other content.
        margin: 0 2 * $global-baseline 0 math.div($picto-size, -5.75);
      }
    }
  }
}
