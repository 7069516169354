@import '../../common/style/styleguide';

.of-public-pages-search-metadata {
  margin-bottom: calc(4 * var(--eds-baseline));

  &-suggestion {
    margin-bottom: calc(2 * var(--eds-baseline));

    a {
      font-style: italic;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
