@import '../../common/style/styleguide';

.of-pbx-work-hours {
  border: 1px solid var(--eds-color-grey-300);
  padding-top: calc(3 * var(--eds-baseline));
  padding-left: 30px;
  padding-right: 30px;
  min-width: 340px;

  @include breakpoint(phone) {
    padding-left: calc(0.5 * var(--eds-baseline));
    padding-right: calc(0.5 * var(--eds-baseline));
  }

  & &__panel-container {
    // Need specific selector to override Grid margin
    margin: 0;
  }

  & &__panel {
    // Need specific selector to override Grid padding
    padding: 0;
  }

  &__errors {
    color: var(--eds-color-grey-500);
    padding-top: calc(1.5 * var(--eds-baseline));
    padding-bottom: var(--eds-baseline);
  }

  &__disclaimer {
    padding-bottom: 2px;
  }

  &__range {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-top: calc(1.5 * var(--eds-baseline));
  }

  &__range-element {
    display: flex;
    flex-direction: column;
    max-width: 145px;

    div {
      font-size: calc(3 * var(--eds-baseline));
    }

    .ds-input {
      margin-bottom: var(--eds-baseline);
    }

    & .ds-inputerror {
      display: none;
    }
  }

  &__range-name {
    align-self: flex-end;
    width: 100%;
  }

  &__range-delimiter {
    padding-left: calc(2 * var(--eds-baseline));
    padding-right: calc(2 * var(--eds-baseline));

    &__editing {
      padding-bottom: calc(2.5 * var(--eds-baseline));
    }
  }

  &__range-errors {
    color: $ds-color--error;
    padding-bottom: calc(3 * var(--eds-baseline));
    font-size: 1.3rem;
  }
}
