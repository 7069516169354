@import '../../common/style/styleguide';

.of-change-offer-dialog {
  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: var(--eds-baseline) 0;
    border-top: 1px solid var(--eds-color-grey-300);
  }
  .disclaimer {
    font-size: 13px;
  }
}
