@import '../../common/style/styleguide';

.of-contact-public {
  // TODO these font-size styles are here because of component-library-overrides so remove this when overrides are removed
  .ds-button--size-m,
  .ds-button--size-l {
    font-size: 13px;
  }

  .heading {
    margin-bottom: 28px;

    @media screen and (min-width: $breakpoint-l) {
      margin-bottom: 64px;
    }
  }

  .content {
    max-width: 910px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: $breakpoint-l) {
      flex-wrap: nowrap;
    }

    .of-login {
      flex: 100%;
      border-right: 1px solid $ds-color__white;

      @include breakpoint(phone) {
        padding: calc($global-baseline * 1.5);
      }

      @media screen and (min-width: $breakpoint-l) {
        flex: 50%;
        border-right-color: var(--eds-color-grey-300);
        padding: 0 64px 0 0;
      }
    }

    .info {
      flex: 100%;
      margin: 0 auto;
      padding: 0 32px;

      @include breakpoint(phone) {
        padding: 0 var(--eds-baseline);
      }

      @media screen and (min-width: $breakpoint-l) {
        flex: 50%;
        padding: 0 0 0 64px;

        h3 {
          margin-top: 0;
        }
      }

      .image {
        width: 288px;
        height: 204px;
        margin: 16px 0;

        @media screen and (min-width: $breakpoint-s) {
          width: 343px;
          height: 243px;
        }

        @media screen and (min-width: $breakpoint-m) {
          width: 366px;
          height: 260px;
        }

        @media screen and (min-width: $breakpoint-xl) {
          margin: 0 0 16px 0;
        }

        background: left/contain url('../../common/svg/art-createcase-public.svg') no-repeat;
      }
    }
  }
}
