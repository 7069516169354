@import '../../common/style/styleguide';

.of-product-grid {
  .ea-fgrid__item {
    overflow: visible;
    will-change: auto;
  }

  .product-element-title {
    margin-top: 36px;
  }

  .product-grid-item__image {
    // Make sure image doesn't affect layout (or anything else) outside itself.
    // This should make rendering faster, and guarantee that lazy loaded images
    // won't cause reflow.
    contain: layout paint style;
  }

  .of_product_price_badge {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  h2.of-product-name {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5em;
    padding: 0;
  }

  h3.of-product-price {
    padding: 0;
    margin: 0;
    font-weight: 600;
    line-height: 28px;
  }

  .of-product-grid-header {
    @include breakpoint(tablet) {
      .ds-grid__col--m-2 {
        overflow: auto;
        flex-basis: calc(2 * 100% / 8);
      }
    }
  }

  .of-product-grid-search {
    max-width: 300px;
    padding: $global-baseline $global-baseline 0 $global-baseline;

    .ds-input input[type='search'] {
      border-radius: $global-borderradius * 2;
    }

    @include breakpoint(phone-large-and-down) {
      max-width: unset;
    }
  }

  &__sort {
    padding: $global-baseline !important;

    &-dropdown {
      div {
        width: 100%;

        button:focus {
          border: none;
          box-shadow: none;
        }

        button {
          border: 0;
          color: $ds-color__blue-600;
          font-family: $global-font-brand-bold;
          font-size: calc(var(--eds-baseline) * 2.5);
          font-weight: 400;
          overflow: hidden;
          padding-left: $global-baseline;
          text-overflow: ellipsis;
          white-space: nowrap;
          @include breakpoint(tablet-and-up) {
            text-align: center;
          }
        }
      }
    }
  }

  &__filter-status {
    padding: $global-baseline !important;

    @include breakpoint(tablet-and-up) {
      text-align: center;
    }

    &-link {
      cursor: pointer;
      display: block;
      overflow: hidden;
      padding: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include breakpoint(tablet-and-up) {
        padding: $global-baseline 0 0;
      }

      font-family: $global-font-brand-bold;

      .filter-amount {
        background-color: #0068f0;
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        font-size: 15px;
        font-weight: 600;
        height: 28px;
        line-height: 23px;
        margin: -1px 8px 0 0;
        padding: 3px;
        text-align: center;
        width: 28px;
        box-sizing: border-box;
      }

      .filter-text {
        color: $ds-color__blue-600;
        font-family: $global-font-brand-bold;
        font-size: calc(var(--eds-baseline) * 2.5);
        margin: 0;
      }

      &.disabled {
        color: #b0b0b0;
        cursor: default;

        .filter-amount {
          background-color: #b0b0b0;
        }

        .filter-text {
          color: #b0b0b0;
        }
      }
    }
  }

  &__filter-header {
    padding: $global-baseline !important;

    @include breakpoint(tablet-and-up) {
      .ds-accordion__section__heading--title {
        text-align: center;
      }
    }

    .of-linkable-accordion:first-of-type {
      border: none;

      :hover {
        background-color: unset;
      }

      section {
        box-shadow: none;

        h3 button {
          padding-left: $global-baseline;
          padding-top: $global-baseline;

          span {
            color: $ds-color__blue-600;
          }
        }

        div {
          height: 0;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  &__filter-content {
    border-top: 1px solid $ea-color-smoke;
    padding: $global-baseline * 2;

    @include breakpoint(tablet-and-down) {
      background-color: $ds-color__white;
      display: inline-block;
      overflow-y: scroll;
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 80;
    }

    &--mobile {
      display: none;

      @include breakpoint(tablet-and-down) {
        display: block;
        padding-top: calc($global-baseline / 2);
        width: 100%;
      }
    }

    &__label {
      font-size: 1.5 * $global-base-font-size;
    }

    &__close {
      color: $ds-color__blue-600;
      float: right;

      span {
        cursor: pointer;
      }
    }

    &__show-all {
      cursor: pointer;
      display: block;
      padding-top: $global-baseline;
    }

    fieldset > legend {
      padding-top: $global-baseline;
      padding-bottom: $global-baseline;
    }
  }

  &__no-products {
    padding: $global-baseline * 2;
  }

  .of-product-grid-header {
    max-width: none;
    margin-bottom: -$global-baseline;
  }

  .of-product-grid-filters {
    max-width: none;
  }

  .grid-items {
    .ds-grid__row {
      margin: 0;
      max-width: 100%;
      border: solid #e0e0e1;
      border-width: 1px 0 0 1px;
    }

    div[class^='ds-grid__col--'],
    div[class*=' ds-grid__col--'] {
      border: solid #e0e0e1;
      border-width: 0 1px 1px 0;
      will-change: auto;
      background: $ds-color__white;
      display: flex;
      flex-direction: row;
      gap: 0;
      padding: 0;
      transition: all 0.2s ease-in-out;
      width: 100%;

      &:focus,
      &:hover {
        border-color: var(--eds-color-grey-400);
        box-shadow: 0 0 12px -0px rgba(black, 0.3);
        transform: scale(1.05);
        transform-origin: center;
      }
    }

    h2.of-product-name {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .product-grid-item__image {
    flex: 0 0 35%;
    max-height: 120px;
    position: relative;
    margin-bottom: 18px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-width: 100%;
      max-height: 100%;
    }
  }

  .product-grid-item__image:before {
    content: '';
    float: left;
    padding-top: 100%;
  }

  .product-grid-link-item {
    display: flex;
    position: relative;
    flex-direction: row;
    flex: 1 0 100%;
    min-height: 100%;
    cursor: pointer;
    padding: 8% 4%;
  }

  .product-grid-item__text {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    padding-left: 8%;
    color: #404041;
  }

  .product-grid-item__price {
    flex-wrap: wrap;
    white-space: nowrap;
    display: flex;
    padding-bottom: 0;
  }

  .product-grid-item__product-name {
    flex-grow: 1;
    padding-bottom: 0;
    font-size: 20px;
    line-height: 1.2em;
  }

  a.product-grid-item:hover {
    text-decoration: none;
  }
}

@media only screen and (min-width: 480px) {
  .of-product-grid {
    .product-grid-link-item {
      flex-direction: column;
      padding: 20% 8% 8%;
    }

    .product-grid-item__image {
      flex-basis: auto;
      max-height: initial;
      margin: 12px 13% 12px;
    }

    .product-grid-item__text {
      padding-top: 24px;
      padding-left: 0;
    }
  }
}
