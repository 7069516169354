@import '../../common/style/styleguide';

.of-create-new-contact {
  .of-details-wrapper__hero-grid-item {
    padding-top: calc(2 * var(--eds-baseline));
  }

  .auto-complete-container {
    max-width: calc(var(--eds-baseline) * 40);
  }

  h1 {
    margin: 0;
    vertical-align: bottom;
  }

  &__h3-with-separator {
    margin-top: calc(1 * var(--eds-baseline));
    padding-top: calc(5 * var(--eds-baseline));
    border-top: 1px solid $ea-color-smoke;
  }

  &__disclaimertext {
    padding-top: $global-baseline * 2.5;
  }

  &__removeButton {
    background-color: $ea-color-error;
    border-color: $ea-color-error;
  }

  &__options {
    display: flex;
    justify-content: space-between;
  }

  &__content form {
    margin-left: calc(var(--eds-baseline) * 2);
  }

  .of-linkable-accordion:first-of-type {
    margin-top: calc(var(--eds-baseline) * 3);
    border-top: 1px solid #e8e8ea;
  }

  &__content {
    .of-contact-form__description {
      margin: 0;

      dl dt button {
        margin-left: calc(var(--eds-baseline) / 2);
      }
    }
  }

  @media screen and (max-width: 640px) {
    & > div:nth-of-type(2) > div {
      margin: 0;
    }

    .of-contact-access-rights-accordion__content {
      margin-right: 5%;
    }

    &__options {
      width: 100%;
      padding-right: 5%;

      div {
        width: 100%;

        button {
          margin: 0;
          width: 49%;
          padding-left: var(--eds-baseline);
          padding-right: var(--eds-baseline);
        }
      }
    }
  }

  @media screen and (min-width: 641px) {
    .of-details-wrapper__hero-right {
      max-width: 100%;
    }

    &__options {
      width: min(687px, calc(100vw - #{calc(9 * var(--eds-baseline))}));
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      div:first-of-type {
        button {
          width: unset;
          padding: 10px 22px;
        }
      }

      div:nth-of-type(2) {
        display: flex;
      }
    }
  }
}
