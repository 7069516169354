@import '../../common/style/styleguide';

@include breakpoint(tablet-and-up) {
  .of-billing-account-details {
    // FIXME: This hack is needed to get around Safari bug where the list doesn't fit into the view
    .ea-accordion__element--expanded #billElectronicOperator .ea-combobox--open .ea-combobox__options {
      max-height: 160px;
    }
  }

  .of-billing-account-contact {
    .ea-combobox__item:first-child:not(.ea-combobox__item--selected) {
      color: $ea-color-linkblue;
    }
  }
}

.of-info-field {
  &__value {
    display: flex;
    align-items: flex-end;
  }
  &__highlight {
    background-color: $ds-color__orange-100;
  }
  &__highlight-text {
    padding: 0 var(--eds-baseline);
  }
}

.of-billing-account-details {
  &__content {
    section#deliveryMethodDetails {
      overflow: visible;
      > div {
        overflow: visible;
      }
    }
  }

  .of-select-one-list__item {
    padding: calc(var(--eds-baseline) * 2);
  }

  .of-billing-account-scheduled-change {
    .ds-disclaimer {
      margin: calc(var(--eds-baseline) * 4) 0;
    }
  }

  .of-linkable-accordion__topmost {
    margin-top: 20px;
  }

  .ea-composite-field--combo-box .ea-input__label {
    margin-bottom: -8px;
  }

  .ds-grid__row {
    .bottom {
      padding-bottom: 32px;
    }
  }
  &__delivery-methods {
    .of-billing-account-details {
      padding-left: 0;
    }
    .ds-grid__row {
      max-width: 912px;
    }
  }

  .tooltip {
    .ds-tooltip {
      padding: 10px;
      width: 384px;
      max-width: 88vw;
    }

    .ds-icon {
      position: relative;
      top: 3px;
    }
  }

  .add-new {
    padding-left: 0;
  }

  .form-column {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 4px;
    min-width: 250px;
    width: 100%;
    @include breakpoint(tablet-and-up) {
      min-width: auto;
    }
  }

  div &__buttons {
    max-width: fit-content;
  }

  .edit-button {
    padding-right: 0 !important;
    min-width: 100%;
    button {
      min-width: 100%;
      white-space: nowrap;
    }
  }

  .view-field:nth-child(even) {
    padding-bottom: 20px;
    @include breakpoint(tablet-and-up) {
      padding-right: 32px;
      padding-left: 48px;
    }
  }

  .view-field:nth-child(odd) {
    padding-bottom: 20px;
    @include breakpoint(tablet-and-up) {
      padding-right: 32px;
    }
  }

  .edit-field:nth-child(even) {
    max-height: 100px;
    @include breakpoint(tablet-and-up) {
      padding-left: 32px;
    }
  }

  .edit-field:nth-child(odd) {
    max-height: 100px;
    @include breakpoint(tablet-and-up) {
      padding-right: 32px;
    }
  }

  .edit-field.no-max-height {
    max-height: unset;
  }

  .bottom {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .button-column {
    flex-basis: auto;
    max-width: inherit;
  }

  .space_for_error {
    @include breakpoint(tablet-and-up) {
      min-height: 110px;
    }

    @include breakpoint(desktop) {
      min-height: 0;
    }
  }
}
