@import '../../common/style/styleguide';

.of-report-list {
  padding-left: 40px;
  padding-top: calc(3 * var(--eds-baseline));
  padding-right: 40px;

  > h2 {
    padding-bottom: $global-baseline;
  }
}

.of-reports {
  margin: 0 16px;

  @media screen and (min-width: $breakpoint-m) {
    margin: 0 24px;
  }

  @media screen and (min-width: $breakpoint-l) {
    margin: 0;
  }

  .report-disclaimer {
    margin-top: 2em;
    padding: 1em;
    padding-top: 1em;
    background-color: $ds-color__neutral-200--light;

    h3 {
      margin-top: 0;
    }

    strong {
      display: block;
      margin-top: 1em;
    }
  }

  .ds-notificationbanner {
    background-color: $ds-color__secondary-blue-100 !important;

    &--visible {
      transition: none;
    }

    .of-report-details {
      line-height: 2;
    }
  }

  .ds-notificationbanner.loader {
    background-color: white !important;
  }
}
