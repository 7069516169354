@import '../../common/style/styleguide';

.of-sticker {
  // Magic numbers, this matches the shape of the original PNG
  aspect-ratio: calc(680 / 705);
  background: $ds-color__orange-700;
  // Magic numbers, this matches the shape of the original PNG
  border-radius: 52% 65%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: var(--eds-header-font-family);
  justify-content: center;
  padding: 5%;
  text-align: center;
  width: fit-content;
}
